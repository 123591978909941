﻿export class RioAuditApiModel {
    auditID: number;
    controllerID: number;
    userID: number;
    dateTime: Date;
    auditActionID: number;
    key1: number;
    key2: number;
    key3: number;
    key4: number;
    column: string;
    oldValue: string;
    newValue: string;
    userName: string;
    description: string;
    screen: string;
}