﻿export class RioAuditSearchEntityApiModel {
    controllerID?: number;
    controllerName?: string;
    key1Table?: string;
    key1ColumnName?: string;
    key1Value?: number;
    key2Table?: string;
    key2ColumnName?: string;
    key2Value?: number;
    key3Table?: string;
    key3ColumnName?: string;
    key3Value?: number;
    key4Table?: string;
    key4ColumnName?: string;
    key4Value?: number;
}