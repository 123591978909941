﻿import * as React from "react";
import { RioValidatedInput } from "../helpers/Validator";
import { RioIfFunc, RioForEach } from "../helpers/logic";


export class RioTextArea extends RioValidatedInput<HTMLTextAreaElement> {
    render() {
        const hasValidationErrors = !!this.props.validator &&
            (this.props.validator.submitAttempted || this.state.blurred) &&
            this.props.validator.getErrors(this).length > 0;

        return (
            <React.Fragment>
                <textarea {...this.props}
                    className={`${!!this.props.className ? this.props.className : ""} ${hasValidationErrors ? "fieldValidationFailed" : ""}`}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.handleInputChanged(e)}
                    onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => this.handleInputBlurred(e)}
                    ref={this.target}
                >
                </textarea>
                
                <RioIfFunc
                    condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)}
                    then={() => {
                        return <RioForEach items={this.props.validator.getErrors(this)} perform={error => {
                            return <div key={error} className="fieldValidationError">{error}</div>;
                        }} />;
                    }}
                />
            </React.Fragment>
        );
    }
}