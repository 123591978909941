import * as React from "react";
import { RioMenuItemApiModel } from "../../apiModels/MenuItemApiModel";
import { RioResources } from "../../helpers/Resources";
import { RioUnsafe } from "../Unsafe";


interface ISideMenuItemProps {
    item: RioMenuItemApiModel;
    active: Boolean;
    menuItem: string;
    itemClicked: (item: RioMenuItemApiModel) => void;
    itemMiddleClicked?: (item: RioMenuItemApiModel) => void;
}

export class RioSideMenuItem extends React.Component<ISideMenuItemProps> {
    render() {
        let resource = RioResources.find(this.props.item.resourceName);
        if (!resource) return null;

        // Before
        var classes = "sideMenuMainItem" + (this.props.active ? " active" : "");

        // // After
        // var classes = classNames("sideMenuMainItem", {"active": this.props.active});

        return (
            <div className={"sideMenuMainItem" + (this.props.active ? " active" : "")} data-menu={this.props.menuItem} onClick={() => this.props.itemClicked(this.props.item)}>
                <RioUnsafe html={resource.image} /><br />
                <span className="text">{resource.text}</span>
            </div>
        );
    }
}