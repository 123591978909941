﻿import * as React from "react";
import { IRioResource } from "../helpers/Resources";
import { RioUnsafe } from "./Unsafe";

interface IIconProps {
    resource: IRioResource;
    className?: string;
    onClick?: () => void;
}

export class RioIcon extends React.Component<IIconProps> {
    render() {
        return (
            <RioUnsafe html={this.props.resource.image} title={this.props.resource.text} className={"icon " + (this.props.className || "")} onClick={() => !!this.props.onClick && this.props.onClick()} />
        );
    }
}