﻿import * as React from "react";
import { IRioResource, RioResources } from "../helpers/Resources";
import { IRioAction } from "../helpers/IAction";
import { RioForEach, RioIf } from "../helpers/logic";
import { RioModal } from "./Modal";
import { RioIconButton } from "./IconButton";


interface IConfirmModalProps {
    text?: IRioResource;
    title?: IRioResource;
    formattedText?: string;
    formattedTitle?: string;
    onConfirmModalClosed: (confirmed: boolean) => void;
    className?: string;
    hideImage?: boolean;
    actions?: Array<IRioAction>;
}

export class RioConfirmModal extends React.Component<IConfirmModalProps> {
    render() {
        return <RioModal visible={true} onCancel={() => this.props.onConfirmModalClosed(false)} className={this.props.className}>
            <div className="titleBar">
                <span className="ui-dialog-title">
                    {this.props.formattedTitle ?? this.props.title?.text ?? "Confirm"}
                </span>
            </div>
            <div>
                <p>
                    {this.props.formattedText ?? this.props.text.text}
                </p>
            </div>
            <RioIf condition={!!this.props.actions} then={
                <div className="buttons">
                    <RioForEach items={this.props.actions} perform={(a: IRioAction) => {
                        if (a.hidden) return null;

                        if (!!a.customDisplay) {
                            return (a.customDisplay());
                        } else {
                            return (
                                <RioIconButton resource={a.resource} onClick={() => a.onClick()} hideImage={!!a.hideImage} key={a.resource.text} hasPermission={a.hasPermission} disabled={a.disabled} />
                            );
                        }
                    }}/>
                </div>
            }
            otherwise={
                <div className="buttons">
                    <RioIconButton resource={RioResources.find("Yes")} onClick={() => this.props.onConfirmModalClosed(true)} hasPermission={true} hideImage={this.props.hideImage} />
                    <RioIconButton resource={RioResources.find("No")} onClick={() => this.props.onConfirmModalClosed(false)} hasPermission={true} hideImage={this.props.hideImage} />
                </div>
            }/>
        </RioModal>;
    }
}
