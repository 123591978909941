﻿import * as React from "react"
import * as ZXing from "@zxing/library/esm5";
import { RioResources } from "../helpers/Resources";
import { RioIf } from "../helpers/logic";
import { RioIcon } from "./Icon";


interface IQRCodeReaderProps {
    onResult: (result: string) => void;
    onReset: () => void;
}

interface IQRCodeReaderState {
    reader: any;
    selectIsOpen: boolean;
    active: boolean;
    deviceID: string;
    stream: MediaStream;
}

export class RioQRCodeReader extends React.Component<IQRCodeReaderProps, IQRCodeReaderState> {
    constructor(props: Readonly<IQRCodeReaderProps>) {
        super(props);

        this.state = {
            reader: null,
            selectIsOpen: false,
            active: false,
            deviceID: null,
            stream: null
        };
    }

    render() {
        return (
            <React.Fragment>
                <div className="grid no-padding-top">
                    <div className="col-12 cameraControls">
                        <RioIf condition={!this.state.deviceID} then={
                            <label>{RioResources.find("PleaseSelectVideoSource").text}:</label>
                        } otherwise={
                            <RioIf condition={!this.state.active} then={
                                <div id="startButtonWrapper" className="startButtonWrapper">
                                    <a className="cameraButton" id="startButton" onClick={() => this.handleStartClicked()} ><RioIcon className="on" resource={RioResources.find("CameraOn")} /></a>
                                    <p>{RioResources.find("ClickEnableCamera").text}</p>
                                </div>
                            } otherwise={
                                <div id="resetButtonWrapper" className="resetButtonWrapper">
                                    <a className="cameraButton reset" id="resetButton" onClick={() => this.handleCameraStopped()}><RioIcon className="off" resource={RioResources.find("CameraOff")} /></a>
                                </div>
                            } />
                        } />
                    </div>
                </div>
                <div className="grid">
                    <div className="col-12">
                        <video className="qrVideo" id="video" poster="/images/placeholder.jpg"></video>
                    </div>
                </div>
                <div className="grid" id="sourceSelectPanel">
                    <div className="col-12">
                        <label>{RioResources.find("ChangeVideoSource").text}:</label>
                        <div className="QRCameraDropDown">
                            <select id="sourceSelect" name="sourceSelect" onClick={(e) => this.setState({ selectIsOpen: !this.state.selectIsOpen })} onBlur={() => this.setState({ selectIsOpen: false })}></select>
                            <span className="arrow">
                                {this.state.selectIsOpen ? <RioIcon resource={RioResources.find("ChevronUp")} /> : <RioIcon resource={RioResources.find("ChevronDown")} /> }
                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            console.log("This browser does not support the API yet");
            return;
        };

        //requests camera permissions
        const stream = navigator.mediaDevices.getUserMedia({
            video: true
        }).then((mediaStream) => {
            this.setUpDevices();
            this.setState({
                stream: mediaStream
            });
        }).catch((e) => {

        });
    }

    setUpDevices() {
        const codeReader = new ZXing.BrowserQRCodeReader();
        console.log('ZXing code reader initialized');
        const sourceSelect = document.getElementById('sourceSelect');

        codeReader.getVideoInputDevices()
            .then((videoInputDevices) => {
                let selectOption = document.createElement('option');
                selectOption.text = RioResources.find("DefaultSelectOption").text;
                selectOption.value = "";
                sourceSelect.appendChild(selectOption);
                if (videoInputDevices.length >= 1) {
                    videoInputDevices.forEach((element) => {
                        const sourceOption = document.createElement('option');
                        sourceOption.text = element.label;
                        sourceOption.value = element.deviceId;
                        sourceSelect.appendChild(sourceOption);
                    })
                    sourceSelect.onchange = (e: any) => {
                        this.setState({
                            deviceID: e.target.value
                        });
                    };
                    const sourceSelectPanel = document.getElementById('sourceSelectPanel');
                    sourceSelectPanel.style.display = 'block';
                }

                this.setState({
                    reader: codeReader
                });
            })
            .catch((err: any) => {
                console.error(err)
            });
    }

    componentWillUnmount(): void {
        if (!!this.state.reader) {
            this.state.reader.reset();
            this.setState({
                reader: null
            });
        }

        if (!!this.state.stream) {
            this.state.stream.getTracks().forEach((track) => track.stop());
        }
    }

    handleStartClicked() {
        this.setState({
            active: true
        });
        this.handleCameraStarted();
    }

    handleCameraStarted() {
        this.state.reader.decodeFromInputVideoDevice(this.state.deviceID, 'video').then((result: any) => {
            console.log(result);
            this.props.onResult(result.getText());
            this.state.reader.reset();
        }).catch((err: any) => {
            console.error(err);
        });
        console.log(`Started continous decode from camera with id ${this.state.deviceID}`)
    }

    handleCameraStopped() {
        this.setState({
            active: false
        });
        this.state.reader.reset();
        this.props.onReset();
        console.log('Reset.');
    }
}