﻿import { RioNumberHelpers } from "./NumberHelpers";

interface ISplitNumeric {
    text: string;
    value: number;
}

export class RioStringHelpers {
    static removeDiacritics(input: string): string {
        return input.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }

    static splitNumeric(input: string): ISplitNumeric {
        let letters: string = "";
        let numbers: string = "";

        for (let char of input) {
            if (RioNumberHelpers.isNumeric(char)) {
                numbers += char;
            } else {
                letters += char;
            }
        }

        return {
            text: letters,
            value: parseInt(numbers)
        };
    }
}