﻿import * as React from "react";
import { RioResources } from "../../helpers/Resources";
import { RioIterate } from "../../helpers/logic";
import { RioIconButton } from "../IconButton";

interface IPagerProps {
    itemCount: number;
    pageSize: number;
    currentPage: number;

    onPageChanged: (page: number) => void;
}

interface IPagerState {

}

export class RioPager extends React.Component<IPagerProps, IPagerState> {
    constructor(props: Readonly<IPagerProps>) {
        super(props);
    }

    render() {
        if (this.props.pageSize === 0) return null;

        const totalPages = Math.ceil(this.props.itemCount / this.props.pageSize);

        if (totalPages <= 1) return null;

        return (
            <div className="pager">
                <RioIconButton resource={RioResources.find("Previous")} hideText={true} disabled={this.props.currentPage === 1} onClick={() => this.handlePreviousClicked()} hasPermission={true} />
                <select value={this.props.currentPage} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handlePageChanged(e)}>
                    <RioIterate iterations={totalPages} perform={(i) => {
                        return <option key={i} value={i}>{i}</option>;
                    }} />
                </select>
                <RioIconButton resource={RioResources.find("Next")} hideText={true} disabled={this.props.currentPage === totalPages} onClick={() => this.handleNextClicked()} hasPermission={true} />
            </div>
        );
    }

    handlePageChanged(e: React.ChangeEvent<HTMLSelectElement>): void {
        this.props.onPageChanged(parseInt(e.target.value));
    }

    handlePreviousClicked(): void {
        this.props.onPageChanged(this.props.currentPage - 1);
    }

    handleNextClicked(): void {
        this.props.onPageChanged(this.props.currentPage + 1);
    }

    getTotalPages() {
        return this.props.itemCount / this.props.pageSize;
    }
}