﻿import * as React from "react";
import { RioDatePicker } from "../../components/DatePicker";
import { RioIconButton } from "../../components/IconButton";
import { RioResources } from "../../helpers/Resources";
import { RioIcon } from "../../components/Icon";
import { RioDateHelpers } from "../../helpers/DateHelpers";
 

interface IDateFilterComponentProps {
    onFromChanged: (dateFrom: Date) => void;
    onToChanged: (dateTo: Date) => void; //Need 2 functions because of race conditions when clearing filters
    dateFormat: string;
    includeTime: boolean;
    closeFilter: () => void;
}

interface IDateFilterComponentState {
    dateFrom?: Date;
    dateTo?: Date;
}

export class DateFilterComponent extends React.Component<IDateFilterComponentProps, IDateFilterComponentState> {
    constructor(props: Readonly<IDateFilterComponentProps>) {
        super(props);

        this.state = {
            dateFrom: new Date(),
            dateTo: new Date()
        };
    }

    private filterWrapperElement = React.createRef<HTMLTableElement>();
    private dateFromPicker = React.createRef<RioDatePicker>();
    private dateToPicker = React.createRef<RioDatePicker>();
    private dateFormat = !!this.props.dateFormat ? this.props.dateFormat : "DD/MM/YYYY"

    render() {
        return (
            <table ref={this.filterWrapperElement} className="filterWrapper">
                <tbody>
                    <tr className="closeButtonRow">
                        <td></td>
                        <td></td>
                        <td>
                            <RioIconButton resource={RioResources.find("FilterClose")} hideText={true} onClick={() => this.props.closeFilter()} hasPermission={true} />
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("From").text}:</span>
                        </td>
                        <td>
                            <RioDatePicker ref={this.dateFromPicker} name="From" validator={null} from={this.state.dateFrom} onChange={(from: Date, to: Date) => { this.handleFromChanged(from) }} time={this.props.includeTime} dateFormat={this.dateFormat} asUtc={true} />
                        </td>
                        <td>
                            <button className="clearFilterButton" onClick={() => this.dateFromPicker.current.clear()}>
                                <RioIcon resource={RioResources.find("ClearFilterIcon")} />
                            </button>
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("To").text}:</span>
                        </td>
                        <td>
                            <RioDatePicker ref={this.dateToPicker} name="To" validator={null} from={this.state.dateTo} onChange={(from: Date, to: Date) => { this.handleToChanged(from) }} time={this.props.includeTime} dateFormat={this.dateFormat} asUtc={true} />
                        </td>
                        <td>
                            <button className="clearFilterButton" onClick={() => this.dateToPicker.current.clear()}>
                                <RioIcon resource={RioResources.find("ClearFilterIcon")} />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    handleFromChanged(date: Date) {
        this.setState({
            dateFrom: date
        });
        this.props.onFromChanged(date);
        if (!!date && !!this.state.dateTo && date > this.state.dateTo) { //if from > to, set to to be from
            this.dateToPicker.current.handleInputValueChanged(date);
        }
    }

    handleToChanged(date: Date) {
        this.setState({
            dateTo: date,
        });

        this.props.onToChanged(date);
        
        if (!!date && !!this.state.dateFrom && this.state.dateFrom > date) { //if from > to, set from to be to
            this.dateFromPicker.current.handleInputValueChanged(date);
        }
    }

    componentDidUpdate() {
        this.checkIfOffScreen();
    }

    checkIfOffScreen() {
        if (!!this.filterWrapperElement.current) {
            const filterWindowElement = this.filterWrapperElement.current.parentElement;
            const filterWindowElementPosition = filterWindowElement.getBoundingClientRect();

            const tableRowElement = filterWindowElement.parentElement.parentElement;
            const tableRowElementPosition = tableRowElement.getBoundingClientRect();

            if (filterWindowElementPosition.right > tableRowElementPosition.right) {
                filterWindowElement.classList.add("right");
            }
        }
    }

    clearFilter() {
        this.dateFromPicker.current.clear();
        this.dateToPicker.current.clear();
    }
}