﻿import * as React from "react";
import { IRioResource } from "../../helpers/Resources";
import { RioIf } from "../../helpers/logic";
import { RioUnsafe } from "../Unsafe";


interface IRioPageHeadingProps {
    resource: IRioResource;
    className?: string;
    hideImage?: boolean;
    hideText?: boolean;
}

export class RioPageHeading extends React.Component<IRioPageHeadingProps> {
    render() {
        return (
            <section className={`heading ${this.props.className}`}>
                <RioIf condition={!!this.props.resource.image && !this.props.hideImage} then={
                    <RioUnsafe html={this.props.resource.image} className="icon" />
                } />
                <RioIf condition={!this.props.hideText} then={
                    <span>{this.props.resource.text}</span>
                } />
            </section>
        );
    }
}