﻿import * as React from "react";
import { RioResources } from "./Resources";
import { IRioModelErrors } from "../apiModels/IModelErrors";
import { RioArrayHelpers } from "./ArrayHelpers";

export interface IRioValidationComponentProps {
    validator?: RioValidator;
    name: string;
}

export interface IRioValidatedInputProps {
    validations?: IRioValidations;
    hideValidationText?: boolean;
    disableCheckOnBlur?: boolean;
}

export interface IRioValidatedInputState {
    blurred: boolean;
}

export interface IRioValidatedInput {
    getKey: () => string;
    getValue: () => any;
    getValidations: () => IRioValidations;
}

export interface IRioValidations {
    required?: boolean;
    numeric?: boolean;
    minValue?: number;
    maxValue?: number;
    minLength?: number;
    maxLength?: number;
    regex?: { expression: RegExp, message: string };
    custom?: (value: any) => Array<string>;
}

export abstract class RioExtendedValidatedInput<T, TExtendedProps> extends React.Component<React.SelectHTMLAttributes<T> & React.InputHTMLAttributes<T> & IRioValidationComponentProps & IRioValidatedInputProps & TExtendedProps, IRioValidatedInputState> implements IRioValidatedInput {
    protected constructor(props: Readonly<IRioValidationComponentProps & IRioValidatedInputProps & TExtendedProps>) {
        super(props);

        this.state = {
            blurred: false
        };
    }

    target = React.createRef<T>();

    getKey(): string {
        return this.props.name;
    }

    getValue(): any {
        return this.props.value;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    handleInputChanged(e: React.ChangeEvent<T>): void {
        this.setState({ blurred: false });
        !!this.props.onChange && this.props.onChange(e);
    }

    handleInputBlurred(e: React.FocusEvent<T>): void {
        if (this.props.disableCheckOnBlur) return;

        this.setState({ blurred: true });
        !!this.props.validator && this.props.validator.validate(this);
        !!this.props.onBlur && this.props.onBlur(e);
    }

    handleInputLoaded(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    handleInputUnloaded(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    componentDidMount(): void {
        this.handleInputLoaded();
    }

    componentWillUnmount(): void {
        this.handleInputUnloaded();
    }
}

export abstract class RioValidatedInput<T> extends RioExtendedValidatedInput<T, {}> { }

export class RioValidator {
    constructor() {
    }

    private registeredComponents: Array<ComponentState> = [];
    submitAttempted = false;


    isValid(component: React.Component): boolean {
        this.submitAttempted = true;
        for (let registeredComponent of this.registeredComponents) {
            this.validate(registeredComponent.input);
        }
        const allValid = !RioArrayHelpers.any(this.registeredComponents, c => !c.valid());
        if (!allValid) component.forceUpdate();
        return allValid;
    }

    manualCheckIsValid(component: React.Component): boolean {
        return !RioArrayHelpers.any(this.registeredComponents, c => !c.valid());
    }

    addModelErrors(component: React.Component, errors: IRioModelErrors) {
        this.submitAttempted = true;
        for (var key in errors) {
            if (errors.hasOwnProperty(key)) {
                const currentComponent = this.registeredComponents.find(c => c.input.getKey() === key);
                if (currentComponent != undefined) {
                    currentComponent.errors = currentComponent.errors.concat(errors[key]);
                }
            }
        }

        component.forceUpdate();
    }

    clearModelErrors(component: React.Component) {
        for (let i = 0; i < this.registeredComponents.length; i++) {
            if (this.registeredComponents[i] != undefined) {
                this.registeredComponents[i].errors = [];
            }
        }
        component.forceUpdate();
    }

    getErrors(input: IRioValidatedInput): Array<string> {
        const component = this.registeredComponents.find(c => c.input === input);
        if (component == undefined) return [];
        return component.errors;
    }

    validate(input: IRioValidatedInput): void {
        const validations = input.getValidations();
        const value = input.getValue();

        let component = this.registeredComponents.find(c => c.input === input);
        if (component == undefined) {
            this.register(input);
        } else {
            component.errors = [];
        }

        if (validations == undefined) return;

        let errors: Array<string> = [];

        if (validations.required) {
            if (value == null || (Array.isArray(value) && value == []) || value.toString().trim().length <= 0) {
                errors.push(RioResources.find("FieldRequired").text);
            }
        } else {
            if (value == null || (Array.isArray(value) && value == []) || value.toString().trim().length === 0) {
                component.errors = errors;
                return;
            }
        }

        if (!!validations.regex && !!validations.regex.expression) {
            if (!validations.regex.expression.test(value)) {
                errors.push(validations.regex.message);
            }
        }

        if (validations.numeric) {
            if (!this.isNumeric(value)) {
                errors.push(RioResources.find("NotANumber").text);
            }
        }

        if (!!validations.minValue || !!validations.maxValue) {
            if (!this.isNumeric(value)) {
                errors.push(RioResources.find("NotANumber").text);
            }
        }

        if (this.isNumeric(value) && !!validations.minValue) {
            if (parseFloat(value) < validations.minValue) {
                errors.push(RioResources.find("NumberTooSmall").text.replace("{minValue}", validations.minValue.toString()));
            }
        }

        if (this.isNumeric(value) && !!validations.maxValue) {
            if (parseFloat(value) > validations.maxValue) {
                errors.push(RioResources.find("NumberTooLarge").text.replace("{maxValue}", validations.maxValue.toString()));
            }
        }

        if (!!validations.minLength) {
            if (value == null || value.toString().length < validations.minLength) {
                errors.push(RioResources.find("TooShort").text.replace("{minLength}", validations.minLength.toString()));
            }
        }

        if (!!validations.maxLength) {
            if (value == null || value.toString().length > validations.maxLength) {
                errors.push(RioResources.find("TooLong").text.replace("{maxLength}", validations.maxLength.toString()));
            }
        }

        if (!!validations.custom) {
            errors = errors.concat(validations.custom(value));
        }

        component.errors = errors;
    }

    isNumeric(value: any): boolean {
        return (!isNaN(parseFloat(value)) && isFinite(value));
    }

    register(input: IRioValidatedInput): void {
        const component = new ComponentState(input);
        this.registeredComponents.push(component);
    }

    unregister(input: IRioValidatedInput): void {
        const component = this.registeredComponents.find(c => c.input === input);
        if (component != undefined) {
            this.registeredComponents = RioArrayHelpers.except(this.registeredComponents, component);
        }
    }
}

class ComponentState {
    constructor(input: IRioValidatedInput) {
        this.input = input;
    }

    input: IRioValidatedInput;
    errors: Array<string> = [];
    valid: () => boolean = () => this.errors.length === 0;
}