﻿import * as React from "react";
import { RouteComponentProps } from "react-router-dom"
import { RioMenuItemApiModel } from "../apiModels/MenuItemApiModel";
import { RioForEach } from "../helpers/logic";
import { RioUnsafe } from "./Unsafe";
import { RioResources } from "../helpers/Resources";
import { RioApiGet } from "../helpers/apiHelpers/Api";

interface IUserDropDownMenuProps extends Partial<RouteComponentProps> {
    show: boolean;
    menuItemEndpoint: string;
}

interface IUserDropDownMenuState {
    menuItems: Array<RioMenuItemApiModel>;
}

export class RioUserDropDownMenu extends React.Component<IUserDropDownMenuProps, IUserDropDownMenuState> {
    constructor(props: Readonly<IUserDropDownMenuProps>) {
        super(props);

        this.state = {
            menuItems: []
        };
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <React.Fragment>
                <ul className="rioUserDropDown">
                    <RioForEach items={this.state.menuItems} perform={(item: RioMenuItemApiModel) => {
                        var href = "/" + (item.controller ? item.controller.replace("Controller", "") + "/" : "") + item.controllerAction;

                        return (
                            <li key={item.menuItemID} className="rioUserDropDownItem">
                                <a className="rioUserDropDownItemLink" onClick={() => this.handleMenuItemClicked(item)} href={href}>
                                    <span className="menu-icon">
                                        <RioUnsafe html={RioResources.find(item.icon).image} />
                                    </span>

                                    {RioResources.find(item.resourceName).text}
                                </a>
                            </li>
                        );
                    }} />
                </ul>
            </React.Fragment>
        );
    }

    componentDidMount(): void {
        this.loadMenu();
    }

    loadMenu() {
        RioApiGet.asPromise<Array<RioMenuItemApiModel>>(this.props.menuItemEndpoint)
            .then(response => {
                this.setState({
                    menuItems: response,
                });
            });
    }

    handleMenuItemClicked(item: RioMenuItemApiModel): void {
        if (!item.controllerAction) {
            return;
        }

        this.props.history.push(item.controllerAction);
    }
}