﻿export class RioMenuItemApiModel {
    menuItemID: number;
    areaName: string;
    controller: string;
    controllerAction: string;
    resourceName: string;
    newWindow: boolean;
    icon: string;
    label: string;

    childItems: Array<RioMenuItemApiModel>;
}