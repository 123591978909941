﻿import * as React from "react";
import { RioForEach, RioIf } from "../helpers/logic";
import { RioResources } from "../helpers/Resources";
import { RioIconButton } from "./IconButton";
import { RioUnsafe } from "./Unsafe";
import { RioFileExtensionApiModel } from "../apiModels/FileExtensionApiModel";

interface IFileDropZoneProps {
    disabled?: boolean;
    onFilesAdded: (files: Array<File>) => void;
    removeFileClicked?: (index: number) => void;
    fileList: Array<File>
    multiple?: boolean;
    fileExtensions?: Array<RioFileExtensionApiModel>;
    fileInputAcceptString?: string;
    hideFilesInDropZone?: boolean;
    dropZoneWording?: string;
}

interface IFileDropZoneState {

}

export class RioFileDropZone extends React.Component<IFileDropZoneProps, IFileDropZoneState> {
    constructor(props: Readonly<IFileDropZoneProps>) {
        super(props);

        this.state = {
        };
    }

    private fileInput = React.createRef<HTMLInputElement>();

    render() {
        return (
            <div
                className="filedropzone"
                onClick={() => this.handleDropzoneClicked()}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => this.handleDropzoneDrop(e)}
                style={{ cursor: this.props.disabled ? "default" : "pointer" }}
            >
                <RioIf condition={!!this.props.hideFilesInDropZone || this.props.fileList.length == 0} then={
                    <RioIf condition={!!this.props.dropZoneWording} then={
                        <div className="filedropzoneWording">
                            {this.props.dropZoneWording}
                        </div>
                    } otherwise={
                        <div className="filedropzoneWording">
                            {RioResources.find("FileDropTarget").text}
                        </div>
                    } />
                        
                    
                } />
                
                <div>
                    <input
                        ref={this.fileInput}
                        type="file"
                        multiple={!!this.props.multiple ? true : false}
                        onInput={(e: React.FormEvent<HTMLInputElement>) => this.onFilesAdded(e)}
                        accept={this.props.fileInputAcceptString}
                    />
                    <RioIf condition={!this.props.hideFilesInDropZone} then={
                        <RioForEach items={this.props.fileList} perform={(item: File, index: number) => {
                            return (
                                <div className="dropZoneFileWrapper" key={index}>
                                    <RioIf condition={!!this.props.removeFileClicked} then={
                                        <RioIconButton className="dropZoneFileRemoveFileButton" resource={RioResources.find("RemoveDropZoneFile")} hideText={true} onClick={() => this.props.removeFileClicked(index)} hasPermission={true} />
                                    } />

                                    <div className="dropZoneFileIconWrapper">
                                        <RioUnsafe className="dropZoneFileIcon" html={this.getResourceIconForFile(item)} />
                                    </div>
                                    <span className="dropZoneFileText">{item.name}</span>
                                </div>
                            );
                        }} />
                    } />
                    
                </div>

            </div>
        );
    }

    componentDidMount() {

    }

    onFilesAdded(e: React.FormEvent<HTMLInputElement>) {
        if (this.props.disabled) return;

        const files = e.currentTarget.files;

        if (!!this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    fileListToArray(list: FileList): Array<File> {
        const array: Array<File> = [];
        for (let i = 0; i < list.length; i++) {
            if (!this.fileContainedInArray(list.item(i), this.props.fileList)) {
                array.push(list.item(i));
            }
        }
        return array;
    }

    handleDropzoneClicked() {
        if (this.props.disabled) return;

        this.fileInput.current.click();
    }

    handleDropzoneDrop(e: React.DragEvent<HTMLDivElement>) {
        e.preventDefault();
        if (this.props.disabled) return;

        const files = e.dataTransfer.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);
            this.props.onFilesAdded(array);
        }
    }

    fileContainedInArray(file: File, fileArray: Array<File>) {
        var result = false;
        for (let i = 0; i < fileArray.length; i++) {
            if ((file.name == fileArray[i].name
                && file.type == fileArray[i].type
                && file.size == fileArray[i].size
                && file.lastModified == fileArray[i].lastModified)) {
                result = true;
                break;
            }
        }
        return result;
    }

    getResourceIconForFile(file: File) {
        var extension = file.name.split('.').pop();
        if (extension != undefined && this.props.fileExtensions != undefined) {
            var extensionInfo = this.props.fileExtensions.find(x => x.extension == extension);
            if (extensionInfo != undefined && extensionInfo.resourceName != undefined) {
                return RioResources.find(extensionInfo.resourceName).image;
            } else {
                return RioResources.find("FileImage").image;
            }
        } else {
            return RioResources.find("FileImage").image;
        }
    }
}