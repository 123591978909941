﻿import * as React from "react";
import { IGridViewFilter } from "../IGridViewFilter";
import { NumberFilterComponent } from "./NumberFilterComponent";


export class NumberFilter implements IGridViewFilter {
    constructor(private callback: () => void, private closeFilter: () => void) {
    }

    minvalue: number = null;
    maxvalue: number = null;

    validate(value: string | number): boolean {
        if (value !== null &&
            ((value >= this.minvalue && value <= this.maxvalue) ||
                (value >= this.minvalue && this.maxvalue === null) ||
                (this.minvalue === null && value <= this.maxvalue) ||
                (this.minvalue === null && this.maxvalue === null))) {
            return true;
        } else if (value === null && this.minvalue === null && this.maxvalue === null) {
            return true;
        } else {
            return false;
        }
    }

    private minMaxFilterComponent = React.createRef<NumberFilterComponent>();

    render() {
        return <NumberFilterComponent
            ref={this.minMaxFilterComponent}
            onChange={(minvalue, maxvalue) => {
                if (maxvalue >= minvalue || maxvalue === null || minvalue === null) {
                    this.minvalue = minvalue;
                    this.maxvalue = maxvalue;
                    this.callback();
                }
            }}
            closeFilter={this.closeFilter}
        />;
    }

    clearFilter() {
        this.minvalue = null;
        this.maxvalue = null;
        this.minMaxFilterComponent.current.clearFilter();
        this.callback();
    }

    filterActive(): boolean {
        if (this.minvalue === null && this.maxvalue === null) {
            return false;
        } else {
            return true;
        }
    }
}
