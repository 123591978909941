﻿import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { ApiResponse } from "../../helpers/apiHelpers/ApiResponse";
import { RioForEach } from "../../helpers/logic";
import { RioApiGet } from "../../helpers/apiHelpers/Api";
import { RioMenuItemApiModel } from "../../apiModels/MenuItemApiModel";
import { RioToggleSwitch } from "../ToggleSwitch";
import { RioSideMenuItem } from "./SideMenuItem";
import { RioSideMenuSubItem } from "./SideMenuSubItem";

interface ILayoutParams {
}

interface ISideMenuProps extends RouteComponentProps<ILayoutParams> {
    menuEndpoint: string;
    pinnedMenuItem: string;

    setPinnedMenuItem: (currentMenuItem?: string) => void;
}

interface ISideMenuState {
    open: boolean;
    menuItems: Array<RioMenuItemApiModel>;
    currentMenuItem?: RioMenuItemApiModel;
}

export class RioSideMenuComponent extends React.Component<ISideMenuProps, ISideMenuState> {
    constructor(props: Readonly<ISideMenuProps>) {
        super(props);

        this.state = {
            open: !!this.props.pinnedMenuItem,
            menuItems: []
        };
    }

    render() {
        var classes = ["sideMenu"];
        if (!!this.props.pinnedMenuItem) classes.push('pinned');
        if (this.state.open) classes.push('open');

        return (
            <nav className={classes.join(' ')}>
                <div className="sideMenuPin">
                    <RioToggleSwitch id="pinMenu" on={!!this.props.pinnedMenuItem} onClick={() => this.handlePinClick()} />
                </div>

                <div className="sideMenuMainItems">
                    <RioForEach items={this.state.menuItems} perform={(item: RioMenuItemApiModel) => {
                        return <RioSideMenuItem item={item} menuItem={item.resourceName} active={item === this.state.currentMenuItem} itemClicked={(item) => this.handleSideMenuItemClicked(item)} itemMiddleClicked={(item) => this.handleSideMenuItemMiddleClicked(item)} key={item.menuItemID} />;
                    }} />
                </div>

                <div className="sideMenuSubItems">
                    {!!this.state.currentMenuItem &&
                        <RioForEach items={this.state.currentMenuItem.childItems} perform={(item: RioMenuItemApiModel) => {
                            return <RioSideMenuSubItem item={item} itemClicked={(item) => this.handleSideMenuSubItemClicked(item)} itemMiddleClicked={(item) => this.handleSideMenuSubItemMiddleClicked(item)} key={item.menuItemID} />;
                        }} />
                    }
                </div>
            </nav>
        );
    }

    componentDidMount(): void {
        this.loadMenu();
    }

    loadMenu() {
        const call = new RioApiGet<Array<RioMenuItemApiModel>>(this.props.menuEndpoint);

        call.onDone = (response: ApiResponse<RioMenuItemApiModel[]>) => {
            if (response.success) {
                this.setState({
                    menuItems: response.item
                }, () => {
                    if (!this.props.pinnedMenuItem) {
                        return;
                    }

                    var pinnedMenuItem = this.props.pinnedMenuItem;

                    if (!pinnedMenuItem) {
                        return;
                    }

                    var currentMenuItem = this.state.menuItems.find(function (element) {
                        return element.resourceName === pinnedMenuItem;
                    });

                    this.setState({
                        currentMenuItem: currentMenuItem
                    });
                });
            }
        };

        call.send();
    }

    handlePinClick() {
        this.props.setPinnedMenuItem(!!this.props.pinnedMenuItem ? null : this.state.currentMenuItem?.resourceName);
    }

    handleSideMenuItemClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            this.props.history.push(item.controllerAction.startsWith('/') ? item.controllerAction : '/' + item.controllerAction);
            return;
        }

        let newCurrentMenuItem: RioMenuItemApiModel = item;

        if (this.state.currentMenuItem === newCurrentMenuItem) {
            if (!!this.props.pinnedMenuItem) {
                return;
            }

            newCurrentMenuItem = null;
        }

        this.setState({
            open: newCurrentMenuItem !== null,
            currentMenuItem: newCurrentMenuItem
        }, () => {
            if (!!this.props.pinnedMenuItem) {
                this.props.setPinnedMenuItem(this.state.currentMenuItem?.resourceName);
            }
        });
    }

    handleSideMenuItemMiddleClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            window.open(RioSideMenuComponent.generateUrl(item), "_blank");
        }
    }

    handleSideMenuSubItemClicked(item: RioMenuItemApiModel): void {
        if (!!item.controllerAction) {
            this.props.history.push(item.controllerAction.startsWith('/') ? item.controllerAction : '/' + item.controllerAction);
        }
    }

    handleSideMenuSubItemMiddleClicked(item: RioMenuItemApiModel): void {
        if (!!item.controller) {
            window.open(RioSideMenuComponent.generateUrl(item), "_blank");
        }
    }

    static generateUrl(item: RioMenuItemApiModel): string {
        if (!item.controller) {
            return "#";
        }

        return "/" + item.controller.replace("Controller", "") + "/" + (!!item.controllerAction ? item.controllerAction : "");
    }
}

export default withRouter(RioSideMenuComponent);