﻿import * as React from "react";
import { RioUnsafe } from "./Unsafe";
import { RioResources } from "../helpers/Resources";


interface IRioLoadingIndicatorProps {
    /** Placeholder height. Defaults to 60px */
    height?: string;
    class?: string;
}

export class RioLoadingIndicator extends React.Component<IRioLoadingIndicatorProps> {
    render() {
        const style: React.CSSProperties = {
            height: this.props.height || "60px"
        };

        return (
            <div className={`rioLoadingIndicator ${!!this.props.class ? `${this.props.class}` : ""}`} style={style}>
                <div className="inside">
                    <RioUnsafe html={RioResources.find("Loading").image} />
                </div>
            </div>
        );
    }
}

interface IRioLoadingIfProps {
    condition: boolean;
}

export class RioLoadingIf extends React.Component<IRioLoadingIndicatorProps & IRioLoadingIfProps> {
    render() {
        const { condition, children, ...rest } = this.props;
        if (condition) {
            return <RioLoadingIndicator {...rest} />;
        } else {
            return children;
        }
    }
}

interface IRioLoadingIfFuncProps {
    otherwise: () => JSX.Element | JSX.Element[] | React.Component | React.Component[] | string;
}

export class RioLoadingIfFunc extends React.Component<IRioLoadingIndicatorProps & IRioLoadingIfProps & IRioLoadingIfFuncProps> {
    render() {
        const { condition, otherwise, ...rest } = this.props;
        if (condition) {
            return <RioLoadingIndicator {...rest} />;
        } else {
            return otherwise();
        }
    }
}