﻿import * as React from "react";
import { RioIcon } from "../../components/Icon";
import { RioResources } from "../../helpers/Resources";
import { RioIconButton } from "../../components/IconButton";


interface INumberFilterComponentProps {
    onChange: (minvalue: number, maxvalue: number) => void;
    closeFilter: () => void;
}

interface INumberFilterComponentState {
    minvalue: number;
    maxvalue: number;
}

export class NumberFilterComponent extends React.Component<INumberFilterComponentProps, INumberFilterComponentState> {
    constructor(props: Readonly<INumberFilterComponentProps>) {
        super(props);

        this.state = {
            minvalue: null,
            maxvalue: null
        };
    }

    private filterWrapperElement = React.createRef<HTMLTableElement>();

    render() {

        return (
            <table ref={this.filterWrapperElement} className="filterWrapper">
                <tbody>
                    <tr className="closeButtonRow">
                        <td></td>
                        <td></td>
                        <td>
                            <RioIconButton resource={RioResources.find("FilterClose")} hideText={true} onClick={() => this.props.closeFilter()} hasPermission={true} />
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("From").text}:</span>
                        </td>
                        <td>
                            <input type="number" value={!!this.state.minvalue ? this.state.minvalue : ""} onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onMinValueBackSpace(e)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ minvalue: parseFloat(e.target.value) }); this.props.onChange(parseFloat(e.target.value), this.state.maxvalue); }}></input>
                        </td>
                        <td>
                            <button className="clearFilterButton" onClick={() => { this.setState({ minvalue: null }); this.props.onChange(null, this.state.maxvalue); }}>
                                <RioIcon resource={RioResources.find("ClearFilterIcon")} />
                            </button>
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("To").text}:</span>
                        </td>
                        <td>
                            <input type="number" value={!!this.state.maxvalue ? this.state.maxvalue : ""} onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onMaxValueBackSpace(e)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ maxvalue: parseFloat(e.target.value) }); this.props.onChange(this.state.minvalue, parseFloat(e.target.value)); }}></input>
                        </td>
                        <td>
                            <button className="clearFilterButton" onClick={() => { this.setState({ maxvalue: null }); this.props.onChange(this.state.minvalue, null); }}>
                                <RioIcon resource={RioResources.find("ClearFilterIcon")} />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    componentDidUpdate() {
        this.checkIfOffScreen();
    }

    checkIfOffScreen() {
        if (!!this.filterWrapperElement.current) {
            const filterWindowElement = this.filterWrapperElement.current.parentElement;
            const filterWindowElementPosition = filterWindowElement.getBoundingClientRect();

            const tableRowElement = filterWindowElement.parentElement.parentElement;
            const tableRowElementPosition = tableRowElement.getBoundingClientRect();

            if (filterWindowElementPosition.right > tableRowElementPosition.right) {
                filterWindowElement.classList.add("right");
            }
        }
    }

    onMinValueBackSpace(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8) {
            if (e.currentTarget.value === "") {
                this.setState({ minvalue: null }); this.props.onChange(null, this.state.maxvalue);
            }
        }
    }

    onMaxValueBackSpace(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.keyCode === 8) {
            if (e.currentTarget.value === "") {
                this.setState({ maxvalue: null }); this.props.onChange(this.state.minvalue, null);
            }
        }
    }

    clearFilter() {
        this.setState({
            minvalue: null,
            maxvalue: null
        });
    }
}
