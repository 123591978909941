﻿import * as React from "react";

interface IUnsafeProps {
    html: string;
    className?: string;
    title?: string;
    onClick?: () => void;
}

/**
 * Use for rendering plain HTML within a React component. SHOULD NOT BE USED for any unsanitised user input
 */
export class RioUnsafe extends React.Component<IUnsafeProps> {
    render() {
        if (!this.props.html) return null;

        return <span className={this.props.className || ""} onClick={() => !!this.props.onClick && this.props.onClick()} title={this.props.title || ""} dangerouslySetInnerHTML={{ __html: this.props.html }}></span>;
    }
}