﻿export class RioNumberHelpers {
    static pad(input: number, width: number, replacer?: string) {
        replacer = replacer || "0";
        const inputAsString = input + "";
        return inputAsString.length >= width
            ? inputAsString
            : new Array(width - inputAsString.length + 1).join(replacer) + inputAsString;
    }

    static isNumeric(input: any): boolean {
        return !isNaN(parseFloat(input)) && isFinite(input);
    }

    static getMaxInt() {
        return 2147483647;
    }

    static roundCurrency(input: number): number {
        if (input == null) {
            return null;
        } else {
            return this.roundToDecimalPlaces(input, 2);
        }
    }

    static roundToDecimalPlaces(input: number, decimalplaces: number): number {
        if (input == null) {
            return null;
        } else {
            return Math.round((input * Math.pow(10, decimalplaces)) + Number.EPSILON) / Math.pow(10, decimalplaces);
        }
    }

    static numberWithCommas(input: number, dp: number = 2): string {
        if (!input) return (0).toFixed(dp);
        return input.toFixed(dp).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}