/**
 * Used for updating an object in an array without mutation.
 * Useful for updating an object in an array in React state
 * @example this.setState({ items: ArrayHelpers.updateObject(this.state.items, item, { field: newValue }) });
 */
export class RioArrayHelpers {
    static updateObject<T>(items: Array<T>, item: T, itemAttributes: Partial<T>): Array<T> {
        const index = items.indexOf(item);
        if (index === -1) {
            throw "Item not found in array";
        }

        return [
            ...items.slice(0, index),
            Object.assign({}, items[index], itemAttributes),
            ...items.slice(index + 1)
        ];
    }

    static any<T>(items: Array<T>, predicate?: (item: T) => boolean): boolean {
        if (predicate == null) return items.length > 0;
        return (items.findIndex(predicate) > -1);
    }

    static contains<T>(items: Array<T>, item: T): boolean {
        return items.indexOf(item) > -1;
    }

    static distinct<T>(items: Array<T>): Array<T> {
        return items.filter((value: T, index: number, self: Array<T>) => { return self.indexOf(value) === index });
    }

    static except<T>(items: Array<T>, item: T): Array<T> {
        if (!this.contains(items, item)) return items;

        const copy = [...items];

        copy.splice(items.indexOf(item), 1);

        return copy;
    }

    static exceptAny<T>(items: Array<T>, predicate: (item: T) => boolean): Array<T> {
        const copy: Array<T> = [];

        for (let currentItem of items) {
            if (!predicate(currentItem)) {
                copy.push(currentItem);
            }
        }

        return copy;
    }

    static first<T>(items: Array<T>, predicate: (item: T) => boolean): T {
        if (predicate == null) return items[0];

        const filtered = items.filter((i: T) => predicate(i));
        if (filtered.length === 0) return null;
        return filtered[0];
    }

    static last<T>(items: Array<T>, predicate: (item: T) => boolean): T {
        if (predicate == null) return items[items.length - 1];

        const filtered = items.filter((i: T) => predicate(i));
        if (filtered.length === 0) return null;
        return filtered[filtered.length - 1];
    }

    static page<T>(items: Array<T>, pageNumber: number, pageSize: number): Array<T> {
        return this.take(this.skip(items, (pageNumber - 1) * pageSize), pageSize);
    }

    static skip<T>(items: Array<T>, total: number): Array<T> {
        if (total > items.length) return [];
        return items.slice(total, items.length);
    }

    static sum<T>(items: Array<T>, predicate: (item: T) => number): number {
        let total = 0;

        for (let currentItem of items) {
            total += predicate(currentItem);
        }

        return total;
    }

    static take<T>(items: Array<T>, total: number): Array<T> {
        if (items.length <= total) return [...items];
        return items.slice(0, total);
    }
}