import { ApiResponse } from "./ApiResponse";
import { ApiErrorHandler } from "./ApiErrorHandler";
import { IRioModelErrors } from "../../apiModels/IModelErrors";

export abstract class ApiRequest<T>{
    uri: string;
    abstract send(): void;
    onDone: (this: ApiRequest<T>, response: ApiResponse<T>) => any;

    xhrOnLoad(xhr: XMLHttpRequest) : any {
        var response = new ApiResponse<T>();
        response.statusCode = xhr.status;
        response.success = xhr.status <= 300;
        if (response.success) {
            if (xhr.responseText) {
                response.item = JSON.parse(xhr.responseText) as T;
            }
        } else {
            if((xhr.status === 401 || xhr.status == 302) && !!ApiErrorHandler.onUnauthorised){
                ApiErrorHandler.onUnauthorised(response);
            }
            else if(xhr.status === 503 && !!ApiErrorHandler.onServiceUnavailable){
                ApiErrorHandler.onServiceUnavailable(response);
            }
            if (xhr.responseText) {
                response.errors = JSON.parse(xhr.responseText) as IRioModelErrors;
            }
        }
        !!this.onDone && this.onDone(response);
    }

    xhrOnLoadGetFile(xhr: XMLHttpRequest) : any {
        if (xhr.response.type === "application/json") {
            const response = new ApiResponse<T>();
            const contentTypeHeader = xhr.getResponseHeader("Content-Type");
            response.statusCode = xhr.status;
            response.success = xhr.status <= 300;
            if(!response.success){
                if((xhr.status === 401 || xhr.status == 302) && !!ApiErrorHandler.onUnauthorised){
                    ApiErrorHandler.onUnauthorised(response);
                }
                else if(xhr.status === 503 && !!ApiErrorHandler.onServiceUnavailable){
                    ApiErrorHandler.onServiceUnavailable(response);
                }
            }
            var reader = new FileReader();
            reader.onload = () => {
                response.item = JSON.parse(reader.result.toString()) as T;
                !!this.onDone && this.onDone(response);
            }
            const fullBlob = new Blob([xhr.response], { type: contentTypeHeader });
            reader.readAsText(fullBlob);

        } else {
            const blob = xhr.response;
            const header = xhr.getResponseHeader("Content-Disposition");
            const response = new ApiResponse<T>();
            response.statusCode = xhr.status;

            response.success = xhr.status <= 300;
            if (response.success) {
                const filename = this.getFileNameByContentDisposition(header);
                const downloadLink = window.document.createElement("a");
                const contentTypeHeader = xhr.getResponseHeader("Content-Type");
                downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader }));
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
            else{
                if((xhr.status === 401 || xhr.status == 302) && !!ApiErrorHandler.onUnauthorised){
                    ApiErrorHandler.onUnauthorised(response);
                }
                else if(xhr.status === 503 && !!ApiErrorHandler.onServiceUnavailable){
                    ApiErrorHandler.onServiceUnavailable(response);
                }
            }
            !!this.onDone && this.onDone(response);
        }
        
    }

    getFileNameByContentDisposition(contentDisposition: string): string {
        const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/;
        const matches = regex.exec(contentDisposition);
        let filename: string = "";

        if (matches != null && matches[3]) {
            filename = matches[3].replace(/['"]/g, "");
        }

        if (filename.includes("; filename")) {
            const endIndex = filename.indexOf("; filename");
            filename = filename.substring(0, endIndex);
        }
        return decodeURI(filename);
    }
}