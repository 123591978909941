﻿import * as React from "react";
import { RioNumberHelpers } from "../helpers/NumberHelpers";
import { RioIf } from "../helpers/logic";

interface IFinancialProps {
    value: number;
    currencySymbol: string;
    exchangeRate: number;
    reverse: boolean;
    showOnlyPound?: boolean;
}


interface IFinancialState {

}

class RioSimpleFinancial extends React.Component<IFinancialProps, IFinancialState> {
    static defaultProps = {
        currencySymbol: "£",
        exchangeRate: 1,
        reverse: false,
        showOnlyPound: true
    };

    constructor(props: Readonly<IFinancialProps>) {
        super(props);

        this.state = {

        };
    }

    render() {
        return (
            <div className="financial-currency-value">
                <span dangerouslySetInnerHTML={{ __html: this.props.currencySymbol }} />
                <span>{RioNumberHelpers.numberWithCommas(this.props.value)}</span>
            </div>    
        );
    }
}

export class RioFinancial extends RioSimpleFinancial {
    static defaultProps = {
        reverse: false,
        currencySymbol: "",
        exchangeRate: 1,
        showOnlyPound: false
    };

    constructor(props: Readonly<IFinancialProps>) {
        super(props);

        this.state = {

        };
    }

    applyExchangeRate(input: number, reverse: boolean = false): string {
        if (!input) return "0.00";

        let convertedInput = this.props.exchangeRate ? (reverse ? (input / this.props.exchangeRate) : (input * this.props.exchangeRate)) : input;
        return isNaN(convertedInput) ? "0.00" : RioNumberHelpers.numberWithCommas(convertedInput);
    }

    render() {
        if (!this.props.exchangeRate) {
            return (
                <div>
                    <span>No Exchange Rate Found!</span>
                </div>
            );
        }

        var hide = (this.props.currencySymbol === "&pound;");

        if (hide) {
            if (!!this.props.showOnlyPound) {
                return null;
            }

            return (
                <div className="rioFinancial-wrapper">
                    <div className="financial-pound-value">
                        <div dangerouslySetInnerHTML={{ __html: this.props.currencySymbol }} />
                        <div>{this.props.value ? RioNumberHelpers.numberWithCommas(this.props.value) : "0.00"}</div>
                    </div>
                </div>
            );
        }

        return (
            <RioIf condition={!this.props.reverse} then={
                <div className="rioFinancial-wrapper">
                    <RioIf condition={!this.props.showOnlyPound} then={
                        <div className="financial-currency-value">
                            <div dangerouslySetInnerHTML={{ __html: this.props.currencySymbol }} />
                            <div>{this.applyExchangeRate(this.props.value)}</div>
                        </div>
                    }/>
                    <div className="financial-pound-value">
                        (<div>£</div>
                        <div>{RioNumberHelpers.numberWithCommas(this.props.value)}</div>)
                    </div>
                </div>
            } otherwise={
                <div className="rioFinancial-wrapper">
                    <RioIf condition={!this.props.showOnlyPound} then={
                        <div className="financial-currency-value">
                            <div dangerouslySetInnerHTML={{ __html: this.props.currencySymbol }} />
                            <div>{RioNumberHelpers.numberWithCommas(this.props.value)}</div>
                        </div>
                    } />
                    <div className="financial-pound-value">
                        (<div>£</div>
                        <div>{this.applyExchangeRate(this.props.value, this.props.reverse)}</div>)
                    </div>
                </div>
            } />
        );
    }
}