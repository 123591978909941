﻿import * as React from "react";
import { IRioResource, RioResources } from "../helpers/Resources";
import { IRioAction } from "../helpers/IAction";
import { RioForEach, RioIf } from "../helpers/logic";
import { RioIconButton } from "./IconButton";
import { RioUnsafe } from "./Unsafe";


interface IExpandingPanelProps {
    /** Title of the panel. Appended resource if specified */
    title?: string | React.Component | React.Component[] | JSX.Element | JSX.Element[];
    /** Resource to use for the heading - overrides any value in the title property */
    resource?: IRioResource;
    /** Additional content for the heading when collapsed - NOTE: not rendered on small screens */
    collapsedHeadingContent?: string | React.Component | React.Component[] | JSX.Element | JSX.Element[];
    /** Array of actions which are displayed as buttons in the title of the panel */
    actions?: Array<IRioAction>;
    /** Whether the panel is expanded by default */
    expandedDefault: boolean;
    /** Whether to disable toggling of the panel by clicking in the title of the panel */
    disableToggle?: boolean;
    /** Custom ClassName */
    className?: string;
}

interface IExpandingPanelState {
    expanded: boolean;
}

export class RioExpandingPanel extends React.Component<IExpandingPanelProps, IExpandingPanelState> {
    constructor(props: Readonly<IExpandingPanelProps>) {
        super(props);

        this.state = {
            expanded: props.expandedDefault
        };
    }

    private headingElement = React.createRef<HTMLDivElement>();
    private bodyElement = React.createRef<HTMLDivElement>();

    render() {
        let headingClass = "heading";
        if (!this.props.disableToggle) {
            headingClass = headingClass + " expandable";
        }

        let title: JSX.Element;
        if (!!this.props.resource) {
            title = (
                <React.Fragment>
                    {!!this.props.resource.image && <RioUnsafe html={this.props.resource.image} className="leftIcon" />}
                    <span className="text">{`${this.props.resource.text} ${!!this.props.title ? this.props.title : ""}`}</span>
                </React.Fragment>
            );
        } else {
            title = <span className="text">{this.props.title}</span>;
        }

        return (
            <section className={`expandingPanel ${!!this.props.className ? this.props.className : ""} ${!this.state.expanded ? "collapsed" : ""}`}>
                <div className={headingClass} onClick={() => this.handleHeadingClicked()} ref={this.headingElement}>
                    {title}
                    <RioIf condition={!this.props.disableToggle} then={
                        <RioUnsafe className="indicator" html={RioResources.find("CaretDown").image} />
                    } />
                    <RioIf condition={!!this.props.actions} then={
                        <span className="actions">
                            <RioForEach items={this.props.actions} perform={(a: IRioAction) => {
                                if (a.hidden) return null;

                                if (!!a.customDisplay) {
                                    return (a.customDisplay());
                                } else {
                                    return (
                                        <RioIconButton resource={a.resource} onClick={() => a.onClick()} hideImage={!!a.hideImage} key={a.resource.text} hasPermission={a.hasPermission} disabled={a.disabled} />
                                    );
                                }
                            }}/>
                        </span>
                    }/>
                    <RioIf condition={(!this.state.expanded && !!this.props.collapsedHeadingContent)} then={
                        <span className="content">
                            {this.props.collapsedHeadingContent}
                        </span>
                    } />
                </div>

                <div className="body" ref={this.bodyElement}>
                    {this.props.children}
                </div>
            </section>
        );
    }

    handleHeadingClicked(): void {
        if (!this.props.disableToggle) {
            $(this.bodyElement.current).slideToggle(0);
            this.setState({
                expanded: !this.state.expanded
            });
        }
    }
}