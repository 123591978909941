﻿export class RioImageDataHelper {

    dataUrl: string;
    type: string;

    constructor(dataUrl: string, type: string) {
        this.dataUrl = dataUrl
        this.type = type
    }

    /* minify the image
    */
    minify(options: any = {}) {
        return new Promise((resolve, reject) => {
            const maxWidth = options.maxWidth || 800
            const maxHeight = options.maxHeight || 800
            const quality = options.quality || .8
            if (!this.dataUrl) {
                return reject({ message: '[error] QuillImageDropAndPaste: Fail to minify the image, dataUrl should not be empty.' })
            }
            const image = new Image()
            image.onload = () => {
                const width = image.width
                const height = image.height
                if (width > height) {
                    if (width > maxWidth) {
                        image.height = height * maxWidth / width
                        image.width = maxWidth
                    }
                } else {
                    if (height > maxHeight) {
                        image.width = width * maxHeight / height
                        image.height = maxHeight
                    }
                }
                const canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                var ctx = canvas.getContext('2d')
                ctx.drawImage(image, 0, 0, image.width, image.height)
                const canvasType = this.type || 'image/png'
                const canvasDataUrl = canvas.toDataURL(canvasType, quality)
                resolve(new RioImageDataHelper(canvasDataUrl, canvasType))
            }
            image.src = this.dataUrl
        })

    }

    /* convert blob to file
    */
    toFile(filename: string) {
        if (!window.File) {
            console.error('[error] QuillImageDropAndPaste: Your browser didnot support File API.')
            return null
        }
        return new File([this.toBlob()], filename, { type: this.type })
    }

    /* convert dataURL to blob
*/
    toBlob() {
        const base64 = this.dataUrl.replace(/^[^,]+,/, '')
        const buff = this.binaryStringToArrayBuffer(atob(base64))
        return this.createBlob([buff], { type: this.type })
    }

    /* create blob
*/
    createBlob(parts: any = [], properties: any = {}) {
        if (typeof properties === 'string') properties = { type: properties }

        return new Blob(parts, properties)

    }

    /* generate array buffer from binary string
    */
    binaryStringToArrayBuffer(binary: string) {
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const arr = new Uint8Array(buffer)
        let i = -1
        while (++i < len) arr[i] = binary.charCodeAt(i)
        return buffer
    }
}