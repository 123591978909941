﻿import * as React from "react";
import moment from "moment";
import { IGridViewFilter } from "../IGridViewFilter";
import { DateFilterComponent } from "./DateFilterComponent";


export class DateFilter implements IGridViewFilter {
    constructor(private callback: () => void, private closeFilter: () => void, private dateFormat: string, private includeTime: boolean) {
    }

    dateFrom: Date;
    dateTo: Date;

    validate(value: string | number): boolean {
        return true;
    }

    private dateFilterComponent = React.createRef<DateFilterComponent>();

    render() {
        return (
            <DateFilterComponent
                ref={this.dateFilterComponent}
                dateFormat={this.dateFormat}
                onFromChanged={(dateFrom) => {
                    this.dateFrom = dateFrom
                    this.callback();
                }}
                onToChanged={(dateTo) => {
                    this.dateTo = dateTo;
                    this.callback();
                }}
                includeTime={this.includeTime}
                closeFilter={this.closeFilter}
            />
        );
    }

    clearFilter() {
        this.dateFilterComponent.current.clearFilter();
    }

    filterActive(): boolean {
        if (!!this.dateFrom || !!this.dateTo) {
            return true;
        } else {
            return false;
        }
    }
}
