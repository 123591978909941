import * as React from "react";
import { RioIf, RioForEach } from "../../helpers/logic";


interface ITreeNodeProps<T> {
    item: T;
    childItems?: (item: T) => Array<T>;
    title: (item: T) => string;
    content?: (item: T) => JSX.Element | JSX.Element[] | React.Component | React.Component[] | string;
    expandedByDefault?: boolean;
    itemKey: (item: T) => string | number;
}

interface ITreeNodeState {
    expanded: boolean;
}

export class RioTreeNode<T> extends React.Component<ITreeNodeProps<T>, ITreeNodeState> {
    constructor(props: Readonly<ITreeNodeProps<T>>) {
        super(props);

        this.state = {
            expanded: !!props.expandedByDefault
        };
    }

    render() {
        return (
            <div className="node" key={this.props.itemKey(this.props.item)}>
                <div className="title" onClick={() => this.handleTitleClicked()}>
                    {!!this.state.expanded ? <i className="fa fa-minus-square"></i> : <i className="fa fa-plus-square"></i>}
                    <span> {this.props.title(this.props.item)}</span>
                </div>

                <RioIf condition={!!this.state.expanded} then={
                    <React.Fragment>
                        <RioIf condition={!!this.props.content} then={
                            <div className="content">
                                {this.props.content(this.props.item)}
                            </div>
                        } />
                        <RioIf condition={!!this.props.childItems} then={
                            <div className="subItems">
                                <RioForEach items={this.props.childItems(this.props.item)} perform={(item: T) => {
                                    return <RioTreeNode item={item} childItems={this.props.childItems} title={this.props.title} content={this.props.content} itemKey={this.props.itemKey} key={this.props.itemKey(item)} />
                                }} />
                            </div>
                        } />
                    </React.Fragment>
                }/>
            </div>
        );
    }

    handleTitleClicked(): void {
        this.setState({
            expanded: !this.state.expanded
        });
    }
}