export class RioResources {
    static resources: IRioResources;

    static find(key: string): IRioResource {
        if (RioResources.resources != undefined && key in RioResources.resources) {
            return RioResources.resources[key];
        } else {
            return { text: `**${key}**`, image: "" }
        }
    }
}

export function setRioResources(newResources: IRioResources) {
    RioResources.resources = newResources;
}

export interface IRioResources {
    [key: string]: IRioResource;
}

export interface IRioResource {
    text: string;
    image: string;
}
