﻿import * as React from "react";
import { RioResources, IRioResource } from "../helpers/Resources";
import { RioIf } from "../helpers/logic";
import { RioIcon } from "../components/Icon";


interface ICheckboxProps {
    checkedResource?: IRioResource;
    nullResource?: IRioResource;
    onChange: () => void;
    checked?: boolean;
    readonly?: boolean;
}

export class RioCheckbox extends React.Component<ICheckboxProps> {
    constructor(props: Readonly<ICheckboxProps>) {
        super(props)
    }

    render() {
        var classNameString = "rioCheckBoxWrapper";
        if (!!this.props.readonly) {
            classNameString += " readOnly";
        }
        if (!!this.props.checked) {
            classNameString += " checked";
        }
        
        return (
            <React.Fragment>
                <RioIf condition={this.props.checked} then={
                    <div className={classNameString} tabIndex={0} onKeyDown={(e) => this.onKeyDown(e)} onClick={!!this.props.readonly ? null : this.props.onChange}>
                        <RioIcon resource={this.props.checkedResource ?? RioResources.find("Check")} />
                    </div>
                } otherwise={
                    <RioIf condition={this.props.checked == null} then={
                        <div className={classNameString} tabIndex={0} onKeyDown={(e) => this.onKeyDown(e)} onClick={!!this.props.readonly ? null : this.props.onChange}>
                            <RioIcon resource={this.props.nullResource ?? RioResources.find("CheckboxNullOption")} />
                        </div>
                    } otherwise={
                        <div className={classNameString} tabIndex={0} onKeyDown={(e) => this.onKeyDown(e)} onClick={!!this.props.readonly ? null : this.props.onChange}></div>
                    }/> 
                } />
                
            </React.Fragment>
        );
    }

    onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
        switch (e.key) {
            case " ":
                this.props.onChange();
                break;
            case "Enter":
                this.props.onChange();
                break;
        }
    }
}