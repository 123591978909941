﻿import * as React from "react";
import { RioForEach, RioIfFunc } from "../helpers/logic";
import { RioValidatedInput } from "../helpers/Validator";

export class RioInput extends RioValidatedInput<HTMLInputElement> {
    render() {
        const hasValidationErrors = !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred) && this.props.validator.getErrors(this).length > 0;

        const p = { ...this.props };
        delete p['disableCheckOnBlur'];
        delete p['hideValidationText'];

        return (
            <React.Fragment>
                <input {...p}
                    className={`${!!this.props.className ? this.props.className : ""} ${hasValidationErrors ? "fieldValidationFailed" : ""}`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChanged(e)}
                    onBlur={(e: React.FocusEvent<HTMLInputElement>) => this.handleInputBlurred(e)}
                    ref={this.target} />

                <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                    return <RioForEach items={this.props.validator.getErrors(this)} perform={error => {
                        return <div key={error} className="fieldValidationError">{error}</div>;
                    }} />;
                }} />
            </React.Fragment>
        );
    }
}