import * as React from "react";
import { RioResources } from "../../helpers/Resources";
import { RioUnsafe } from "../Unsafe";


export class RioHelpButton extends React.Component {
    render() {
        return (
            <div className="rioHelpButton">
                <span className="menu-icon" onClick={(e) => this.showDialog()}>
                    <RioUnsafe html={RioResources.find("Help").image} />
                </span>
            </div>
        );
    }

    showDialog(): void {
        window.open('/HelpTool/' + this.parseCurrentUrl(), '', 'width=1000,height=500,left=100,top=100');
    }

    parseCurrentUrl(): string {
        let withoutQueryString = window.location.pathname.split("?")[0];
        let split = withoutQueryString.split("/");
        var ret = [];
        for (var i = 0; i < split.length; i++) {
            if (split[i].length > 0) {
                ret.push(split[i]);
            }
        }
        return ret.join("/");
    }
}