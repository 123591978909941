﻿import * as React from "react";
import { RioAuditSearchEntityApiModel } from "../../apiModels/audit/RioAuditSearchEntityApiModel";
import { RioAuditContext } from "../../constants/RioAuditContext";

export interface IRioAuditedComponentProps {
    controllerID?: number;
    controllerName?: string;
    key1Table?: string;
    key1ColumnName?: string;
    key1Value?: number;
    key2Table?: string;
    key2ColumnName?: string;
    key2Value?: number;
    key3Table?: string;
    key3ColumnName?: string;
    key3Value?: number;
    key4Table?: string;
    key4ColumnName?: string;
    key4Value?: number;
    ignoreAudit?: boolean;
}

export class RioAuditedComponent extends React.Component<IRioAuditedComponentProps>{
    protected constructor(props: Readonly<IRioAuditedComponentProps>) {
        super(props);

    }

    static contextType = RioAuditContext;

    componentDidMount() {
        if (!this.props.ignoreAudit) {
            if (this.context?.auditModel == null) {
                let model = new RioAuditSearchEntityApiModel();
                model.controllerID = this.props.controllerID;
                model.controllerName = this.props.controllerName;
                model.key1Table = this.props.key1Table;
                model.key1ColumnName = this.props.key1ColumnName;
                model.key1Value = this.props.key1Value;
                model.key2Table = this.props.key2Table;
                model.key2ColumnName = this.props.key2ColumnName;
                model.key2Value = this.props.key2Value;
                model.key3Table = this.props.key3Table;
                model.key3ColumnName = this.props.key3ColumnName;
                model.key3Value = this.props.key3Value;
                model.key4Table = this.props.key4Table;
                model.key4ColumnName = this.props.key4ColumnName;
                model.key4Value = this.props.key4Value;

                this.setAuditContext(model);
            }
        }
    }

    componentWillUnmount() {
        if (!this.props.ignoreAudit && this.currentModelIsActive()) {
            this.setAuditContext(null);
        }
    }

    setAuditContext(model: RioAuditSearchEntityApiModel) {
        if (this.context != null) {
            this.context.setModel(model);
        }
    }

    currentModelIsActive() { //this exists to ensure we only clear the context on unmount if it is the active context
        let contextModel = this.context?.auditModel;
        return (contextModel === null ||
            (contextModel.controllerID === this.props.controllerID && contextModel.controllerName === this.props.controllerName &&
            contextModel.key1Table === this.props.key1Table && contextModel.key1ColumnName === this.props.key1ColumnName && contextModel.key1Value === this.props.key1Value &&
            contextModel.key2Table === this.props.key2Table && contextModel.key2ColumnName === this.props.key2ColumnName && contextModel.key2Value === this.props.key2Value &&
            contextModel.key3Table === this.props.key3Table && contextModel.key3ColumnName === this.props.key3ColumnName && contextModel.key3Value === this.props.key3Value &&
            contextModel.key4Table === this.props.key4Table && contextModel.key4ColumnName === this.props.key4ColumnName && contextModel.key4Value === this.props.key4Value));
    }

    render() {
        return (
            this.props.children
        );
    }
}