﻿import * as React from "react";
import { IGridViewFilter } from "../IGridViewFilter"
import { BoolFilterComponent } from "./BoolFilterComponent";

export class BoolFilter implements IGridViewFilter {
    constructor(private callback: () => void, private closeFilter: () => void) {
    }

    filterValue: boolean = null;

    validate(value: boolean): boolean {
        if (this.filterValue == null) {
            return true; //if filter cleared show all
        } else if (value != null && (value == this.filterValue)) {
            return true;
        } else {
            return false;
        }
    }

    private boolFilterComponent = React.createRef<BoolFilterComponent>();

    render() {
        return (
            <BoolFilterComponent
                ref={this.boolFilterComponent}
                onChange={(value) => {
                    this.filterValue = value;
                    this.callback();
                }}
                closeFilter={this.closeFilter}
            />
        );
    }

    clearFilter() {
        this.boolFilterComponent.current.clearFilter();
    }

    filterActive(): boolean {
        if (this.filterValue == null) {
            return false;
        } else {
            return true;
        }
    }
}
