﻿import * as React from "react";
import { RioUnsafe } from "./Unsafe";
import { RioForEach, RioIf } from "../helpers/logic";
import { RioResources } from "../helpers/Resources";
import { RioAutoCompleteResult } from "../apiModels/AutoCompleteResult";


interface IAutoCompleteProps {
    text?: string;
    placeholder?: string;
    keepTextOnSelect: boolean;
    suggestionMinimumChars: number;
    onGetOptions: (text: string) => Promise<Array<RioAutoCompleteResult>>;
    onTextSelected: (item: RioAutoCompleteResult) => void;
    onChange?: (text: string) => void;
    onEnterPress?: (text: string) => void;
    disabled?: boolean;
    showIcon?: boolean;
}

interface IAutoCompleteState {
    text: string;
    value?: string;
    options: RioAutoCompleteResult[];
    focusedOption: RioAutoCompleteResult;
}

export class RioAutoComplete extends React.Component<IAutoCompleteProps, IAutoCompleteState> {
    static defaultProps = {
        showIcon: false
    };

    constructor(props: Readonly<IAutoCompleteProps>) {
        super(props);

        this.state = {
            value: null,
            text: this.props.text ? this.props.text : "",
            options: [],
            focusedOption: null
        };
    }

    componentDidUpdate(prevProps: IAutoCompleteProps, prevState: IAutoCompleteState) {
        if (prevProps.text !== this.props.text) {
            this.setState({ text: this.props.text ? this.props.text : "" });
        }
    }

    handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({ text: e.target.value });

        if (e.target.value.length >= this.props.suggestionMinimumChars) {
            this.props.onGetOptions(e.target.value).then(v => this.setState({ options: v }));
        }
        else {
            this.setState({ options: [] });
        }

        if (this.props.onChange) {
            this.props.onChange(e.target.value);
        }
    }

    handleItemSelected(item: RioAutoCompleteResult) {
        if (this.props.keepTextOnSelect) {
            this.setState({
                text: item.label,
                value: item.value,
            });
        }
        else {
            this.setState({
                text: "",
                value: item.value,
            });
        }

        this.props.onTextSelected(item);
        this.setState({ options: [] });
    }

    handleInputBlur(e: React.FocusEvent<HTMLInputElement>): void {
        this.setState({ options: [] });
    }

    handleKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.keyCode) {
            case 38: //ArrowUp
                if (!!this.state.focusedOption) {
                    let index = this.state.options.findIndex(x => x == this.state.focusedOption);
                    let newFocused: RioAutoCompleteResult;
                    if (index < 1) {
                        newFocused = null;
                    } else {
                        newFocused = this.state.options[index - 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.state.options[this.state.options.length - 1]
                    }, () => this.scrollToFocused());
                }
                break;
            case 40: //ArrowDown
                if (!!this.state.focusedOption) {
                    let index = this.state.options.findIndex(x => x == this.state.focusedOption);
                    let newFocused: RioAutoCompleteResult;
                    if (index >= this.state.options.length - 1) {
                        newFocused = null;
                    } else {
                        newFocused = this.state.options[index + 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.state.options[0]
                    }, () => this.scrollToFocused());
                }
                break;
            case 9: //Tab
                if (!!this.state.focusedOption) {
                    this.handleItemSelected(this.state.focusedOption);
                }
                break;
            case 13: //Enter
                if (!!this.state.focusedOption) {
                    this.handleItemSelected(this.state.focusedOption);
                }
                break;
            default:
                break;
        }
    }

    scrollToFocused() {
        if (!!this.focusedOptionLi.current) {
            this.focusedOptionLi.current.scrollIntoView({
                block: "center"
            });
        }
    }

    private focusedOptionLi: React.RefObject<HTMLLIElement> = React.createRef();
    private autoCompleteInput: React.RefObject<HTMLInputElement> = React.createRef();

    render() {
        return (
            <div className="rioAutoComplete">
                <input
                    ref={this.autoCompleteInput}
                    type="text"
                    value={this.state.text}
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.handleInputChange(e)}
                    onBlur={(e) => this.handleInputBlur(e)}
                    disabled={this.props.disabled}
                    onKeyPress={(e) => this.handleKeyPressed(e)}
                />

                <RioIf condition={this.props.showIcon} then={
                    <RioUnsafe html={RioResources.find("Search").image} />
                } />
                <RioIf condition={(this.state.options?.length ?? 0) > 0} then={
                    <ul>
                        <RioForEach items={this.state.options} perform={(item: RioAutoCompleteResult, index: number) => {
                            return (
                                <li ref={(!!this.state.focusedOption && item == this.state.focusedOption) ? this.focusedOptionLi : null} key={index} className={`${(!!this.state.focusedOption && item == this.state.focusedOption) ? "focused" : ""}`} onMouseDown={() => this.handleItemSelected(item)}>{item.label}</li>
                            );
                        }} />
                    </ul>
                } />
            </div>
        );
    }
}