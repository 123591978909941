﻿import * as React from "react";


interface IRioToggleSwitchProps {
    on: boolean;
    enabled: boolean;
    className: string;
    id?: string;

    buttonText: string;
    toggleLabel: string;

    onClick: (e: any) => void;
}

export class RioToggleSwitch extends React.Component<IRioToggleSwitchProps> {
    static defaultProps = {
        enabled: true,
        className: "",
        toggleLabel: "Unpin",
        buttonText: "Pin Menu"
    }

    render() {
        var classes = ['rioSwitch', this.props.className];
        if (this.props.on) classes.push('on');
        if (!this.props.enabled) classes.push('disabled');

        return (
            <React.Fragment>
                <div id={this.props.id} className={classes.join(' ')} onClick={(e) => this.props.enabled ? this.props.onClick(this) : null}>
                    <span className="rioSwitch-label">{this.props.toggleLabel}</span>
                    <div className="rioSwitch-toggle">{this.props.buttonText}</div>
                </div>
            </React.Fragment>
        );
    }
}