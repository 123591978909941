﻿import * as React from "react";
import { RioForEach, RioIf } from "../helpers/logic";
import { RioStringHelpers } from "../helpers/StringHelpers";
import { RioOmniBoxResultApiModel } from "../apiModels/OmniBoxResultApiModel";


interface IRioOmniboxProps<T> {
    items: Array<T>;
    value: T;
    propertiesToSearch: Array<string>;
    minCharactersToSearch: number;
    minSearchTermLength: number;
    displayValue: (item: T) => string;
    onSelectedItemChanged: (item: T) => void;

    onMouseDown?: (item: RioOmniBoxResultApiModel<T>) => void;
    onEnterPressed?: (item: RioOmniBoxResultApiModel<T>) => void;
    onTabPressed?: (item: RioOmniBoxResultApiModel<T>) => void;
    minMatchesRequired?: number;
    showTop?: number; 
}

interface IRioOmniboxState<T> {
    text: string;
    options: Array<RioOmniBoxResultApiModel<T>>;
    focusedOption: RioOmniBoxResultApiModel<T>;
    focused: boolean;
}

export class RioOmnibox<T> extends React.Component<IRioOmniboxProps<T>, IRioOmniboxState<T>> {
    constructor(props: Readonly<IRioOmniboxProps<T>>) {
        super(props);

        this.state = {
            text: "",
            options: [],
            focusedOption: null,
            focused: false
        };
    }

    private focusedOptionLi: React.RefObject<HTMLLIElement> = React.createRef();

    render() {
        return (
            <div className="rioOmniBox" onFocus={(e) => this.setState({ focused: true })} onBlur={(e) => this.setState({focused: false})}>
                <input
                    type="text"
                    value={this.state.text}
                    onChange={(e) => this.handleInputChange(e.currentTarget.value)}
                    onKeyDown={(e) => this.handleKeyPressed(e)}
                    
                />
                <RioIf condition={!!this.state.focused && this.state.options.length > 0} then={
                    <ul>
                        <RioForEach items={this.state.options} perform={(option, index) => {
                            return (
                                <li key={index} ref={(!!this.state.focusedOption && option == this.state.focusedOption) ? this.focusedOptionLi : null} className={option == this.state.focusedOption ? "focused" : ""} onMouseDown={() => this.handleMouseDown(option)}>{this.props.displayValue(option.item)}</li>
                            );
                        }} />
                    </ul>
                } />
                
            </div>
        );
    }

    handleInputChange(value: string) {
        let results: Array<RioOmniBoxResultApiModel<T>> = [];
        if (value.length >= this.props.minCharactersToSearch) {
            let searchOptions = value.split(" ").filter(x => x.length >= this.props.minSearchTermLength);
            let items = this.props.items;

            for (let i = 0; i < items.length; i++) {
                let matches = 0;
                let item: any = items[i]
                for (let j = 0; j < searchOptions.length; j++) {
                    let searchValue = searchOptions[j];
                    if (!!searchValue) {
                        for (let k = 0; k < this.props.propertiesToSearch.length; k++) {
                            let propToSearch = this.props.propertiesToSearch[k];
                            let itemValue = item[propToSearch];
                            if (!!itemValue) {
                                switch (typeof itemValue) {
                                    case "string":
                                        let itemValueNoDiacritic = RioStringHelpers.removeDiacritics(itemValue).toLowerCase();
                                        let searchValueNoDiacritic = RioStringHelpers.removeDiacritics(searchValue).toLowerCase();
                                        if (itemValueNoDiacritic.includes(searchValueNoDiacritic)) {
                                            matches++;
                                        }
                                        break;
                                    case "number":
                                        if (itemValue == parseFloat(searchValue)) {
                                            matches++;
                                        }
                                        break;
                                    default:
                                        break;
                                }                                
                            }
                        }
                    }
                }

                let minMatchesRequired = this.props.minMatchesRequired ?? 1;

                if (matches >= minMatchesRequired) {
                    let result = new RioOmniBoxResultApiModel<T>();
                    result.item = item;
                    result.matches = matches;

                    results.push(result);
                }
            }
        }

        if (!!this.props.value) {
            this.props.onSelectedItemChanged(null);
        }

        this.setState({
            text: value,
            options: results.sort((a, b) => (a.matches > b.matches) ? -1 : 1).slice(0, this.props.showTop ?? results.length),
            focusedOption: null
        });
    }

    handleKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.keyCode) {
            case 38: //ArrowUp
                if (!!this.state.focusedOption) {
                    let index = this.state.options.findIndex(x => x.item == this.state.focusedOption.item)
                    let newFocused: RioOmniBoxResultApiModel<T>;
                    if (index < 1) {
                        newFocused = null; //this.state.options[this.state.options.length - 1];
                    } else {
                        newFocused = this.state.options[index - 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.state.options[this.state.options.length - 1]
                    }, () => this.scrollToFocused());
                }
                break;
            case 40: //ArrowDown
                if (!!this.state.focusedOption) {
                    let index = this.state.options.findIndex(x => x.item == this.state.focusedOption.item)
                    let newFocused: RioOmniBoxResultApiModel<T>;
                    if (index >= this.state.options.length - 1) {
                        newFocused = null; //this.state.options[0];
                    } else {
                        newFocused = this.state.options[index + 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.state.options[0]
                    }, () => this.scrollToFocused());
                }
                break;
            case 9: //Tab
                if (!!this.state.focusedOption) {
                    this.handleSelectOption(this.state.focusedOption);
                    if (!!this.props.onTabPressed) {
                        this.props.onTabPressed(this.state.focusedOption);
                    }
                }
                break;
            case 13: //Enter
                if (!!this.state.focusedOption) {
                    this.handleSelectOption(this.state.focusedOption);
                    if (!!this.props.onEnterPressed) {
                        this.props.onEnterPressed(this.state.focusedOption);
                    }
                }
                break;
            default:
                break;
        }
    }

    scrollToFocused() {
        if (!!this.focusedOptionLi.current) {
            this.focusedOptionLi.current.scrollIntoView({
                block: "center"
            });
        }
    }

    handleMouseDown(option: RioOmniBoxResultApiModel<T>) {
        this.handleSelectOption(option)
        if (!!this.props.onMouseDown) {
            this.props.onMouseDown(option);
        }
    }

    handleSelectOption(option: RioOmniBoxResultApiModel<T>) {
        this.props.onSelectedItemChanged(option.item);
            this.setState({
                focusedOption: null,
                text: this.props.displayValue(option.item),
                options: []
            });
        
    }
}