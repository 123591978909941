﻿import * as React from "react";
import { RioIcon } from "./Icon";
import { RioSeverity } from "../enums/Severity";
import { IRioResource } from "../helpers/Resources";


interface IStatusIconProps {
    resource: IRioResource;
    size: string;
    severity?: RioSeverity;
    lightBackground?: boolean;
    fillColor?: boolean;
}

export class RioStatusIcon extends React.Component<IStatusIconProps> {
    constructor(props: Readonly<IStatusIconProps>) {
        super(props);
    }

    render() {
        var className = "rioStatusIcon";
        if (this.props.fillColor) {
            switch (this.props.severity) {
                case RioSeverity.success:
                    className = className + " okayFill";
                    break;
                case RioSeverity.warning:
                    className = className + " warningFill";
                    break;
                case RioSeverity.danger:
                    className = className + " dangerFill";
                    break;
                case RioSeverity.info:
                    className = className + " infoFill";
            }
        } else {
            switch (this.props.severity) {
                case RioSeverity.success:
                    className = className + (!this.props.lightBackground ? " okay" : " okayLight");
                    break;
                case RioSeverity.warning:
                    className = className + (!this.props.lightBackground ? " warning" : " warningLight");
                    break;
                case RioSeverity.danger:
                    className = className + (!this.props.lightBackground ? " danger" : " dangerLight");
                    break;
                case RioSeverity.info:
                    className = className + (!this.props.lightBackground ? " info" : " infoLight");
            }
        }
        
        return (
            <div className={className} style={{ height: this.props.size, width: this.props.size }}>
                <RioIcon resource={this.props.resource} />
            </div>
        );
    }
}