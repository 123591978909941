﻿import * as React from "react";
import { IGridViewFilter } from "../IGridViewFilter";
import { TextFilterOption } from "../../enums/TextFilterOption";
import { RioStringHelpers } from "../../helpers/StringHelpers";
import { TextFilterComponent } from "./TextFilterComponent";

export class TextFilter implements IGridViewFilter {
    constructor(private callback: () => void, private closeFilter: () => void) {
    }

    inputValue: string = "";
    dropDownValue: TextFilterOption = TextFilterOption.StartsWith;

    validate(value: string | number): boolean {
        if (!this.filterActive()) {
            return true;
        } else if (value == null) {
            return false;
        } else {
            switch (this.dropDownValue) {
                case TextFilterOption.StartsWith:
                    return RioStringHelpers.removeDiacritics(value.toString().toLowerCase()).startsWith(RioStringHelpers.removeDiacritics(this.inputValue.toLowerCase()));
                case TextFilterOption.EndsWith:
                    return RioStringHelpers.removeDiacritics(value.toString().toLowerCase()).endsWith(RioStringHelpers.removeDiacritics(this.inputValue.toLowerCase()));
                case TextFilterOption.Contains:
                    return RioStringHelpers.removeDiacritics(value.toString().toLowerCase()).includes(RioStringHelpers.removeDiacritics(this.inputValue.toLowerCase()));
                default:
                    return true;
            }
        }
    }

    private startEndsWithFilterComponent = React.createRef<TextFilterComponent>();

    render() {
        return <TextFilterComponent
            ref={this.startEndsWithFilterComponent}
            onChange={(inputValue, dropDownValue) => {
                this.inputValue = inputValue;
                this.dropDownValue = dropDownValue;
                this.callback();
            }}
            closeFilter={this.closeFilter}
        />;
    }

    clearFilter() {
        this.startEndsWithFilterComponent.current.clearFilter();
    }

    filterActive(): boolean {
        return !!this.inputValue
    }
}
