import * as React from "react";
import { RioCheckbox } from "../../components/Checkbox";
import { RioIconButton } from "../../components/IconButton";
import { RioResources } from "../../helpers/Resources";


interface IBoolFilterComponentProps {
    onChange: (value: boolean) => void;
    closeFilter: () => void;
}

interface IBoolFilterComponentState {
    checked: boolean;
}

export class BoolFilterComponent extends React.Component<IBoolFilterComponentProps, IBoolFilterComponentState> {
    constructor(props: Readonly<IBoolFilterComponentProps>) {
        super(props);

        this.state = {
            checked: null
        };
    }

    private filterWrapperElement = React.createRef<HTMLTableElement>();
    private checkBox = React.createRef<RioCheckbox>();

    render() {
        return (
            <table ref={this.filterWrapperElement} className="filterWrapper boolFilter">
                <tbody>
                    <tr className="closeButtonRow">
                        <td></td>
                        <td>
                            <RioIconButton resource={RioResources.find("FilterClose")} hideText={true} onClick={() => this.props.closeFilter()} hasPermission={true} />
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("Filter").text}:</span>
                        </td>
                        <td>
                            <RioCheckbox ref={this.checkBox} checked={this.state.checked} onChange={() => this.handleCheckboxChanged()} />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    handleCheckboxChanged() {
        let newValue: boolean;
        if (this.state.checked == null) {
            newValue = true
        } else if (this.state.checked) {
            newValue = false
        } else {
            newValue = null
        }

        this.changeValueTo(newValue);
    }

    changeValueTo(newValue: boolean) {
        this.setState({
            checked: newValue
        });
        this.props.onChange(newValue);
    }

    componentDidUpdate() {
        this.checkIfOffScreen();
    }

    checkIfOffScreen() {
        if (!!this.filterWrapperElement.current) {
            const filterWindowElement = this.filterWrapperElement.current.parentElement;
            const filterWindowElementPosition = filterWindowElement.getBoundingClientRect();

            const tableRowElement = filterWindowElement.parentElement.parentElement;
            const tableRowElementPosition = tableRowElement.getBoundingClientRect();

            if (filterWindowElementPosition.right > tableRowElementPosition.right) {
                filterWindowElement.classList.add("right");
            }
        }
    }

    clearFilter() {
        this.changeValueTo(null);
    }

}