﻿import * as React from "react";
import { IRioValidationComponentProps, IRioValidatedInputProps, IRioValidatedInputState, IRioValidatedInput, IRioValidations } from "../helpers/Validator";
import { RioIf, RioIfFunc, RioForEach } from "../helpers/logic";
import { RioIcon } from "./Icon";
import { RioResources } from "../helpers/Resources";
import { RioStringHelpers } from "../helpers/StringHelpers";


interface IRioSelectProps<T> {
    items: Array<T>;
    value: Array<T>;
    keySelector: (item: T) => string;
    descriptionSelector: (item: T) => string;
    onSuggestionSelected: (value: T) => void;
    disabled?: boolean;
    keepOptionsOpenOnSelected?: boolean;
    defaultSelectOptionOverride?: string;
    defaultMultipleSelectOptionOverride?: string;
    isMultiSelect?: boolean;
    showCheckboxes?: boolean;
}

interface IRioSelectState<T> {
    focussed: boolean;
    suggestions: Array<T>;
    text: string;
    focusedOption: T;
}

export class RioSelect<T> extends React.Component<IRioSelectProps<T> & IRioValidationComponentProps & IRioValidatedInputProps, 
    IRioSelectState<T> & IRioValidatedInputState> implements IRioValidatedInput {
    constructor(props: Readonly<IRioSelectProps<T> & IRioValidationComponentProps & IRioValidatedInputProps>) {
        super(props);

        this.state = {
            blurred: false,
            focussed: false,
            suggestions: [],
            text: "",
            focusedOption: null
        }
    }

    private input: React.RefObject<HTMLInputElement> = React.createRef();
    private focusedOptionDiv: React.RefObject<HTMLDivElement> = React.createRef();

    getKey(): string {
        return this.props.name;
    }

    getValue(): any {
        return this.props.value;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    handleInputLoaded(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    handleInputUnloaded(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    componentDidMount(): void {
        this.handleInputLoaded();
    }

    componentWillUnmount(): void {
        this.handleInputUnloaded();
    }

    render() {
        let inputValue: string;
        if (this.state.focussed) {
            inputValue = this.state.text;

        } else {
            inputValue = this.getCurrentText(this.props);
        }

        const hasValidationErrors = !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred) && this.props.validator.getErrors(this).length > 0;

        return (
            <React.Fragment>
                <div className={`rioSelect ${!!this.props.disabled ? " disabled" : ""} ${hasValidationErrors ? "fieldValidationFailed" : ""}`}>
                    <div className="chevron" onClick={() => this.input.current.focus()}>
                        <RioIf condition={!this.state.focussed} then={<RioIcon resource={RioResources.find("ChevronDown")} />} 
                            otherwise={<RioIcon resource={RioResources.find("ChevronUp")} />} />
                    </div>
                    <input
                        className={`selection ${this.props.value.length == 0 ? "noneSelected" : ""}` }
                        ref={this.input}
                        value={inputValue}
                        onFocus={() => this.handleInputFocussed()}
                        onBlur={(e) => this.handleInputBlurred(true)}
                        onChange={(e) => this.handleInputChanged(e)}
                        disabled={this.props.disabled}
                        autoComplete={"new-password"}
                        onKeyDown={(e) => this.handleKeyPressed(e)}
                    />
                    {this.renderOptions()}
                </div>

                <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                    return <RioForEach 
                        items={this.props.validator.getErrors(this)} 
                        perform={(error: any) => {
                            return <div key={error} className="fieldValidationError">{error}</div>;
                    }} />;
                }} />
            </React.Fragment>
        );
    }

    getCurrentText(props: Readonly<IRioSelectProps<T> & IRioValidationComponentProps & IRioValidatedInputProps>): string {
        if (props.value.length == 0) {
            if (!!props.defaultSelectOptionOverride) {
                return props.defaultSelectOptionOverride;
            } else {
                return RioResources.find("DefaultSelectOption").text;
            }
        } else if (props.value.length == 1) {
            return props.descriptionSelector(props.value[0]);
        } else {
            if (!!props.defaultMultipleSelectOptionOverride) {
                return props.defaultMultipleSelectOptionOverride;
            } else {
                return RioResources.find("DefaultMultipleSelected")
                    .text.replace("{selectedCount}", props.value.length.toString()).replace("{totalCount}", props.items.length.toString());
            }
        }
    }

    renderOptions() {
        if (!this.state.focussed) return null;
        var shouldRenderCheckboxes = (this.props.showCheckboxes || this.props.isMultiSelect) && this.props.showCheckboxes !== false;
        return (
            <div
                className="options"
            >
                <RioForEach items={this.state.suggestions} perform={(item: T) => {
                    return (
                        <div key={this.props.keySelector(item)} 
                        ref={(!!this.state.focusedOption && item == this.state.focusedOption) ? this.focusedOptionDiv : null} 
                        className={`option ${(!!this.state.focusedOption && item == this.state.focusedOption) ? "focused" : ""}`} 
                        onMouseDown={(e) => this.handleSuggestionSelected(item, e)}>
                            <div>
                                <RioIf condition={this.props.value.find((x: any) => x == item) != null && shouldRenderCheckboxes} then={
                                    <RioIcon resource={RioResources.find("CheckSquare")} />
                                } 
                                otherwise={
                                    <RioIf condition={shouldRenderCheckboxes} 
                                        then={<RioIcon resource={RioResources.find("Square")} />}
                                    />
                                }/>
                                <span>{this.props.descriptionSelector(item)}</span>
                            </div>
                        </div>
                    );
                }} />
            </div>
        );
    }

    handleInputFocussed(): void {
        this.setState({
            focussed: true
        });
        this.input.current.selectionStart = 0;
        this.input.current.selectionEnd = this.input.current.value.length;
        this.getSuggestions(this.state.text);
    }

    handleInputBlurred(validate: boolean): void {
        this.setState({
            focussed: false,
            text: "",
            blurred: true,
            focusedOption: null
        });
        if (validate) {
            !!this.props.validator && this.props.validator.validate(this);
        }
        
    }

    handleInputChanged(e: React.ChangeEvent<HTMLInputElement>): void {
        const newValue = e.currentTarget.value;
        this.setState({
            text: newValue
        });

        this.getSuggestions(newValue);
    }

    handleKeyPressed(e: React.KeyboardEvent<HTMLInputElement>) {
        switch (e.keyCode) {
            case 38: //ArrowUp
                if (!!this.state.focusedOption) {
                    let index = this.props.items.findIndex((x: any) => x == this.state.focusedOption);
                    let newFocused: T;
                    if (index < 1) {
                        newFocused = null;
                    } else {
                        newFocused = this.props.items[index - 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.props.items[this.props.items.length - 1]
                    }, () => this.scrollToFocused());
                }
                break;
            case 40: //ArrowDown
                if (!!this.state.focusedOption) {
                    let index = this.props.items.findIndex((x: any) => x == this.state.focusedOption);
                    let newFocused: T;
                    if (index >= this.props.items.length - 1) {
                        newFocused = null;
                    } else {
                        newFocused = this.props.items[index + 1];
                    }
                    this.setState({
                        focusedOption: newFocused
                    }, () => this.scrollToFocused());
                } else {
                    this.setState({
                        focusedOption: this.props.items[0]
                    }, () => this.scrollToFocused());
                }
                break;
            case 9: //Tab
                break;
            case 13: //Enter
                if (!!this.state.focusedOption) {
                    this.handleSuggestionSelected(this.state.focusedOption, null);
                }
                break;
            default:
                break;
        }
    }

    scrollToFocused() {
        if (!!this.focusedOptionDiv.current) {
            this.focusedOptionDiv.current.scrollIntoView({
                block: "center"
            });
        }
    }

    getSuggestions(value: string) {
        if (value.trim() === "") {
            this.setState({
                suggestions: [...this.props.items]
            });
        } else {
            let searchValue = RioStringHelpers.removeDiacritics(value).toLowerCase();
            this.setState({
                suggestions: this.props.items.filter((i: any) => 
                    RioStringHelpers.removeDiacritics(this.props.descriptionSelector(i)).toLowerCase().includes(searchValue))
            });
        }
    }

    handleSuggestionSelected(item: T, e: React.MouseEvent<HTMLDivElement>) {
        if (e != null) {
            e.preventDefault();
            e.stopPropagation();
        }

        this.props.onSuggestionSelected(item);

        //If explicitly set to keep open then do nothing 
        if (this.props.keepOptionsOpenOnSelected === true)
        {
            return;
        }

        //If explicitly set to close then close
        if (this.props.keepOptionsOpenOnSelected === false)
        {
            this.handleInputBlurred(false);
        }

        //Not explicitly set to keep open or closed, but is a multi select, so keep open
        if(this.props.isMultiSelect){
            return;
        }

        //By default, close
        this.handleInputBlurred(false);
    }
}