import * as React from "react";
import { RioAuditContext } from "../../constants/RioAuditContext";
import { RioAuditContextModel } from "../../apiModels/audit/RioAuditContextModel";
import { RioAuditSearchEntityApiModel } from "../../apiModels/audit/RioAuditSearchEntityApiModel";
import { IRioResource, RioResources } from "../../helpers/Resources";
import { RioIconButton } from "../IconButton";
import { RioIfFunc } from "../../helpers/logic";


interface RioAuditButtonProps {
    hideImage?: boolean;
    hideText?: boolean;
    resource?: IRioResource;
    auditRoute?: string;
}

export class RioAuditButton extends React.Component<RioAuditButtonProps> {
    render() {
        return (
            <RioAuditContext.Consumer>
                {(context: RioAuditContextModel) => (
                    <RioIfFunc condition={context?.auditModel != null} then={() => {
                        return (
                            <div className="rioAuditButton">
                                <RioIconButton 
                                    resource={!!this.props.resource ? this.props.resource : RioResources.find("Audit")} 
                                    hasPermission={true} 
                                    onClick={() => this.buttonClicked(context.auditModel)} 
                                    hideImage={!!this.props.hideImage} 
                                    hideText={!!this.props.hideText}
                                />
                            </div>
                        );
                    }} />
                )}
            </RioAuditContext.Consumer>
        );
    }

    buttonClicked(auditModel: RioAuditSearchEntityApiModel) {
        if (!auditModel) {
            return;
        }

        let path: string = "";
        if (!!this.props.auditRoute) {
            if (!this.props.auditRoute.startsWith("/")) {
                path = "/" + this.props.auditRoute;
            } else {
                path = this.props.auditRoute;
            }
        } else {
            path = "/audit";
        }

        path += this.buildAuditParamsString(auditModel);

        window.open(path, "_blank");
    }

    buildAuditParamsString(auditModel: RioAuditSearchEntityApiModel): string {
        let params = "";
        if (!!auditModel.controllerID) {
            params += `/controllerID=${auditModel.controllerID}`;
        } else if (!!auditModel.controllerName) {
            params += `/controllerName=${auditModel.controllerName}`;
        } else {
            return params; //no controller provided means no audit anyway
        }

        //nested to preserve order. It's not a necessity, but forces users to make sure it is.
        if (!!auditModel.key1Table && !!auditModel.key1ColumnName && !!auditModel.key1Value) {
            params += `&key1Table=${auditModel.key1Table}&key1ColumnName=${auditModel.key1ColumnName}&key1Value=${auditModel.key1Value}`;

            if (!!auditModel.key2Table && !!auditModel.key2ColumnName && !!auditModel.key2Value) {
                params += `&key2Table=${auditModel.key2Table}&key2ColumnName=${auditModel.key2ColumnName}&key2Value=${auditModel.key2Value}`;

                if (!!auditModel.key3Table && !!auditModel.key3ColumnName && !!auditModel.key3Value) {
                    params += `&key3Table=${auditModel.key3Table}&key3ColumnName=${auditModel.key3ColumnName}&key3Value=${auditModel.key3Value}`;

                    if (!!auditModel.key4Table && !!auditModel.key4ColumnName && !!auditModel.key4Value) {
                        params += `&key4Table=${auditModel.key4Table}&key4ColumnName=${auditModel.key4ColumnName}&key4Value=${auditModel.key4Value}`;
                    }
                }
            }
        }

        return params;
    }
}

export default RioAuditButton;