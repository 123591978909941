﻿import * as React from "react";
import { IRioValidatedInputProps, IRioValidatedInputState, IRioValidatedInput, IRioValidations, RioValidator } from "../helpers/Validator";
import { RioForEach, RioIfFunc } from "../helpers/logic";


interface IColourPickerProps {
    onColourChanged: (colour: string) => void;
    value: string;
    name: string;
    validations?: IRioValidations;
    validator: RioValidator;
}

export class RioColourPicker extends React.Component<IColourPickerProps & IRioValidatedInputProps, IRioValidatedInputState> implements IRioValidatedInput {
    constructor(props: Readonly<IColourPickerProps>) {
        super(props);

        this.state = {
            blurred: false
        };
    }

    getKey(): string {
        return this.props.name;
    }

    getValue(): any {
        if (this.props.value && this.props.value.length === 0) {
            return null;
        }

        return this.props.value;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    handleInputLoaded(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    handleInputUnloaded(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    componentDidMount(): void {
        this.handleInputLoaded();
    }

    componentWillUnmount(): void {
        this.handleInputUnloaded();
    }

    private colourOptions: Array<string> = ["#4D4D4D", "#999999", "#D3D3D3", "#F44E3B", "#FE9200", "#FCDC00", "#DBDF00", "#A4DD00", "#68CCCA", "#73D8FF", "#AEA1FF", "#FDA1FF",
                                           "#333333", "#808080", "#CCCCCC", "#D33115", "#E27300", "#FCC400", "#B0BC00", "#68BC00", "#16A5A5", "#009CE0", "#7B64FF", "#FA28FF",
                                           "#000000", "#666666", "#B3B3B3", "#9F0500", "#C45100", "#FB9E00", "#808900", "#194D33", "#0C797D", "#0062B1", "#653294", "#AB149E"]

    render() {
        const hasValidationErrors = !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred) && this.props.validator.getErrors(this).length > 0;

        return (
            <React.Fragment>
                <div className={`rioColourPicker ${hasValidationErrors ? "fieldValidationFailed" : ""}`}>
                    <RioForEach items={this.colourOptions} perform={(colour, index) => {
                        return (
                            <div key={colour} className={"colourOption" + (this.props.value == colour ? " selected" : "")} style={{ backgroundColor: colour }} onClick={() => this.props.onColourChanged(colour)}>

                            </div>
                        );
                    }} />
                </div>
                <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                    return <RioForEach items={this.props.validator.getErrors(this)} perform={error => {
                        return <div key={error} className="fieldValidationError">{error}</div>;
                    }} />;
                }} />
            </React.Fragment>
        );
    }
}