﻿import * as React from "react";
import moment from "moment";
import { RioIcon } from "../components/Icon";
import { RioResources } from "../helpers/Resources";
import { RioIfFunc, RioForEach } from "../helpers/logic";
import { IRioValidationComponentProps, IRioValidatedInputProps, IRioValidatedInputState, IRioValidatedInput, IRioValidations } from "../helpers/Validator";
import DatePicker from "react-datepicker";
import { RioDateHelpers } from "../helpers/DateHelpers";

interface IDatePickerProps {
    /** Enables the user to pick two values which are set in from and to*/
    range?: boolean;

    //** Is this a time picker or a date picker? */
    time?: boolean;

    nullable?: boolean;
    from?: string;
    to?: string;
    minDate?: string;
    maxDate?: string;
    utc?: boolean;
    timePickerIncrement?: number; //Time picker increment in minutes
    disabled?: boolean;
    readonly?: boolean;
    dateFormat?: string;

    onChange: (from: string, to: string) => void;
}

interface IDatePickerState {
    startDate: Date;
    endDate?: Date;
}

export class RioDatePicker extends React.Component<IDatePickerProps & IRioValidationComponentProps & IRioValidatedInputProps, IDatePickerState & IRioValidatedInputState> implements IRioValidatedInput {
    private input = React.createRef<HTMLInputElement>();
    private displayDateFormat: string;
    private dataDateFormat: string;
    private minDate: Date;
    private maxDate: Date;
        
    constructor(props: Readonly<IDatePickerProps & IRioValidationComponentProps & IRioValidatedInputProps>) {
        super(props);

        this.state = {
            blurred: false,
            startDate: RioDateHelpers.parseInputDate(this.props.from, this.props.utc && this.props.time),
            endDate: RioDateHelpers.parseInputDate(this.props.to, this.props.utc && this.props.time)
        };

        this.displayDateFormat = this.calculateDisplayDateFormat();
        this.dataDateFormat = this.calculateDataDateFormat();
        this.minDate = RioDateHelpers.parseInputDate(this.props.minDate, this.props.utc && this.props.time);
        this.maxDate = RioDateHelpers.parseInputDate(this.props.maxDate, this.props.utc && this.props.time);
    }

    getKey(): string {
        return this.props.name;
    }

    getValue(): any {
        return this.props.from;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    render() {
        return (
            <React.Fragment>
                <div className="datePicker">
                    <DatePicker
                        dateFormat={this.displayDateFormat}
                        selected={this.state.startDate}
                        endDate={this.state.endDate}
                        onChange={(date) => this.handleInputValueChanged(date)}
                        selectsRange={this.props.range}
                        showTimeSelect={this.props.time}
                        minDate={this.minDate}
                        maxDate={this.maxDate}
                        readOnly={this.props.readonly}
                        disabled={this.props.disabled}
                    />
                </div>

                <RioIcon resource={RioResources.find("DatePickerCalendar")} />

                <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                    return <RioForEach items={this.props.validator.getErrors(this)} perform={error => {
                        return <div key={error} className="fieldValidationError">{error}</div>;
                    }} />;
                }} />
            </React.Fragment>
        );
    }

    componentDidMount(): void {
        this.handleInputLoaded();
    }

    componentWillUnmount(): void {
        this.handleInputUnloaded();
    }

    handleInputLoaded(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    handleInputUnloaded(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    focus(): void {
        this.input.current.focus();
    }

    clear() {
        this.handleInputValueChanged(null);
    }

    handleInputValueChanged(value: Date | [Date, Date] | /* for selectsRange */ null) {
        if (value === null) {
            this.setState({
                startDate: null,
                endDate: null
            });

            this.props.onChange(null, null);
        } else if (value instanceof Date) {
            this.setState({
                startDate: value,
                endDate: null
            });

            this.props.onChange(RioDateHelpers.parseOutputDate(value, this.dataDateFormat), null);
        } else if (value instanceof Array) {
            this.setState({
                startDate: value[0],
                endDate: null
            });

            this.props.onChange(RioDateHelpers.parseOutputDate(value[0], this.dataDateFormat), RioDateHelpers.parseOutputDate(value[1], this.dataDateFormat));
        }
    }

    calculateDisplayDateFormat(): string {
        if (!!this.props.dateFormat) {
            return this.props.dateFormat;
        } else if (this.props.time) {
            return "dd/MM/yyyy HH:mm:ss";
        } else {
            return "dd/MM/yyyy";
        }
    }
    
    calculateDataDateFormat(): string {
        return "yyyy-MM-dd'T'HH:mm:ss";
    }
}