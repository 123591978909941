﻿import * as React from "react";
import { RioGuid } from "../helpers/Guid";

interface IModalProps {
    visible: boolean;
    className?: string;
    onCancel: () => void;
    backgroundClickDisabled?: boolean;
}


export class RioModal extends React.Component<IModalProps> {
    static stack: Array<string> = [];

    private id = RioGuid.newGuid();
    private static escapeKey = 27;

    render() {
        if (!this.props.visible) return null;
        return (
            <div className="rioModal" onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => this.onBackgroundClicked(e)}>
                <div className={`modalContent ${!!this.props.className ? this.props.className : ""}`} onClick={(e: React.MouseEvent<HTMLDivElement>) => this.onContentClicked(e)}>
                    {this.props.children}
                </div>
            </div>
        );
    }

    componentDidMount(): void {
        document.addEventListener("keydown", (e) => this.onKeyDown(e.keyCode), false);
        RioModal.stack.push(this.id);
    }

    componentWillUnmount(): void {
        RioModal.stack.pop();
        document.removeEventListener("keydown", (e) => this.onKeyDown(e.keyCode), false);
    }

    onBackgroundClicked(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>): void {
        if (e.target !== e.currentTarget || !!this.props.backgroundClickDisabled) {
            return;
        }

        this.closeModal();
    }

    onContentClicked(e: React.MouseEvent<HTMLDivElement>): void {
        e.stopPropagation();
    }

    onKeyDown(keyCode: number): void {
        if (keyCode === RioModal.escapeKey) {
            this.closeModal();
        }
    }

    closeModal(): void {
        if (RioModal.stack[RioModal.stack.length - 1] === this.id) {
            this.props.onCancel();
        }
    }
}