﻿import { ApiRequest } from "./ApiRequest";

export class RioApiGet<T> extends ApiRequest<T> {
    constructor(apiUri: string) {
        super();
        this.uri = apiUri;
    }

    send() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("get", this.uri, true);
        xhr.onload = () => { 
            this.xhrOnLoad(xhr);
        }
        xhr.send();
    }

    sendAndGetFile() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("get", this.uri, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onload = () => { 
            this.xhrOnLoadGetFile(xhr);
        }
        xhr.send();
    }

    /**
     * Performs a GET but returns in the form of a Promise
     */
    static asPromise<T>(apiUrl: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var call = new RioApiGet<T>(apiUrl);
            call.onDone = response => {
                if (response.success) {
                    resolve(response.item);
                } else {
                    reject(response.errors);
                }
            }
            call.send();
        });
    }
}

export class RioApiPost<T> extends ApiRequest<T> {
    constructor(apiUri: string, item: Object) {
        super();
        this.uri = apiUri;
        this.item = item;
    }
    item: Object;

    send() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("post", this.uri, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onload = () => { 
            this.xhrOnLoad(xhr);
        }
        xhr.send(JSON.stringify(this.item));
    }

    sendAndGetFile() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("post", this.uri, true);
        xhr.responseType = "blob";
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.onload = () => { 
            this.xhrOnLoadGetFile(xhr);
        }
        xhr.send(JSON.stringify(this.item));
    }

    /**
     * Performs a POST but returns in the form of a Promise
     */
    static asPromise<T>(apiUrl: string, item: Object): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var call = new RioApiPost<T>(apiUrl, item);
            call.onDone = response => {
                if (response.success) {
                    resolve(response.item);
                } else {
                    reject(response.errors);
                }
            }
            call.send();
        });
    }
}

export class RioApiDelete<T> extends ApiRequest<T> {
    constructor(apiUri: string) {
        super();
        this.uri = apiUri;
    }

    uri: string;

    send() {
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open("delete", this.uri, true);
        xhr.onload = () => { 
            this.xhrOnLoad(xhr);
        }
        xhr.send();
    }

    /**
     * Performs a DELETE but returns in the form of a Promise
     */
    static asPromise<T>(apiUrl: string): Promise<T> {
        return new Promise<T>((resolve, reject) => {
            var call = new RioApiDelete<T>(apiUrl);
            call.onDone = response => {
                if (response.success) {
                    resolve(response.item);
                } else {
                    reject(response.errors);
                }
            }
            call.send();
        });
    }
}