﻿import * as React from "react";
import { RioForEach } from "../../helpers/logic";
import { RioTreeNode } from "./TreeNode";


interface ITreeProps<T> {
    items: Array<T>;
    childItems?: (item: T) => Array<T>;
    title: (item: T) => string;
    content?: (item: T) => JSX.Element | JSX.Element[] | React.Component | React.Component[] | string;
    expandedByDefault?: boolean;
    itemKey: (item: T) => string | number;
}

export class RioTree<T> extends React.Component<ITreeProps<T>> {
    render() {
        return (
            <div className="tree">
                <RioForEach items={this.props.items} perform={(item: T) => {
                    return <RioTreeNode item={item} childItems={(item: T) => this.props.childItems && this.props.childItems(item)} title={(item: T) => this.props.title && this.props.title(item)} content={(item: T) => this.props.content && this.props.content(item)} expandedByDefault={this.props.expandedByDefault} itemKey={this.props.itemKey} key={this.props.itemKey(item)} />
                }} />
            </div>
        );
    }
}

