﻿import * as React from "react";
import moment from "moment";
import { RouteComponentProps } from "react-router";
import { RioAuditApiModel } from "../../apiModels/audit/RioAuditApiModel";
import { RioAuditContext } from "../../constants/RioAuditContext";
import { RioAuditContextModel } from "../../apiModels/audit/RioAuditContextModel";
import { RioAuditSearchEntityApiModel } from "../../apiModels/audit/RioAuditSearchEntityApiModel";
import { IRioResource, RioResources } from "../../helpers/Resources";
import { IGridViewColumn } from "../gridview/IGridViewColumn";
import { IGridViewAction } from "../gridview/IGridViewAction";
import { Filter } from "../../enums/Filter";
import { RioApiPost } from "../../helpers/apiHelpers/Api";
import { RioPage } from "../pageheading/Page";
import { RioGridView } from "../gridview/GridView";
import { RioLoadingIf } from "../LoadingIndicator";


interface RioAuditViewerParams {
    queryString?: string;
}

interface RioAuditViewerProps extends Partial<RouteComponentProps<RioAuditViewerParams>> {
    resource?: IRioResource;
    hidePageImage?: boolean;
    hidePageText?: boolean;
}

interface RioAuditViewerState {
    loading: boolean;
    items: RioAuditApiModel[];
}

export class RioAuditViewer extends React.Component<RioAuditViewerProps, RioAuditViewerState> {
    constructor(props: RioAuditViewerProps) {
        super(props);

        this.state = {
            loading: true,
            items: []
        };
    }
    static contextType = RioAuditContext;

    columns: Array<IGridViewColumn<RioAuditApiModel>> = [
        {
            title: RioResources.find("Description").text, value: (item) => item.description,
            filter: Filter.TextFilter
        },
        {
            title: RioResources.find("Date").text,
            value: (item) => item.dateTime ? new Date(item.dateTime).getTime() : 0,
            displayValue: (item) => item.dateTime ? moment(item.dateTime).format("DD/MM/YYYY") : "",
            filter: Filter.DateFilter
        },
        {
            title: RioResources.find("Key1").text, value: (item) => item.key1,
            filter: Filter.NumberFilter
        },
        {
            title: RioResources.find("Column").text, value: (item) => item.column,
            filter: Filter.TextFilter
        },
        {
            title: RioResources.find("OldValue").text, value: (item) => item.oldValue,
            filter: Filter.TextFilter
        },
        {
            title: RioResources.find("NewValue").text, value: (item) => item.newValue,
            filter: Filter.TextFilter
        },
        {
            title: RioResources.find("UserName").text, value: (item) => item.userName,
            filter: Filter.TextFilter
        },
        {
            title: RioResources.find("Screen").text, value: (item) => item.screen,
            filter: Filter.TextFilter
        }
    ];

    actions: Array<IGridViewAction<RioAuditApiModel>> = [
    ];



    render() {
        return (
            <RioAuditContext.Consumer>
                {(context: RioAuditContextModel) => (
                    <RioLoadingIf condition={!!this.state.loading}>
                        <RioPage heading={!!this.props.resource ? this.props.resource : RioResources.find("AuditViewer")} className="rioAudit" hideImage={this.props.hidePageImage} hideText={this.props.hidePageText}>
                            <RioGridView<RioAuditApiModel>
                                noResults={RioResources.find("NoResultsFound")}
                                columns={this.columns}
                                actions={this.actions}
                                items={this.state.items}
                                rowKey={(item: RioAuditApiModel) => item.auditID}
                            />
                        </RioPage>
                    </RioLoadingIf>
                )}
            </RioAuditContext.Consumer>

        );
    }

    componentDidMount() {
        let contextModel = this.buildContextModel();
        if (!!contextModel) {
            RioApiPost.asPromise<Array<RioAuditApiModel>>("/api/auditviewer/search", contextModel)
                .then(response => {
                    this.setState({
                        items: response,
                        loading: false
                    });
                });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    buildContextModel(): RioAuditSearchEntityApiModel {
        let rawParams = this.props.match.params.queryString.split("&");
        let extractedParams: any = {};
        rawParams.forEach((paramString) => {
            let item = paramString.split("=");
            extractedParams[item[0]] = item[1];
        });

        if (!extractedParams["controllerID"] && !extractedParams["controllerName"]) {
            return null;
        }

        let model = new RioAuditSearchEntityApiModel();
        model.controllerID = parseInt(extractedParams["controllerID"]);
        model.controllerName = extractedParams["controllerName"];
        model.key1Table = extractedParams["key1Table"];
        model.key1ColumnName = extractedParams["key1ColumnName"];
        model.key1Value = parseInt(extractedParams["key1Value"]);
        model.key2Table = extractedParams["key2Table"];
        model.key2ColumnName = extractedParams["key2ColumnName"];
        model.key2Value = parseInt(extractedParams["key2Value"]);
        model.key3Table = extractedParams["key3Table"];
        model.key3ColumnName = extractedParams["key3ColumnName"];
        model.key3Value = parseInt(extractedParams["key3Value)"]);
        model.key4Table = extractedParams["key4Table"];
        model.key4ColumnName = extractedParams["key4ColumnName"];
        model.key4Value = parseInt(extractedParams["key4Value"]);

        return model;
    }
}