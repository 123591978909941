﻿import moment from 'moment';
import { format } from 'date-fns';

moment.locale(navigator.language);

export class RioDateHelpers {
    // --- Moment Date helpers --- //

    static toDateFormat(input: string, format: string): string {
        if (!input || !format) return input;

        return moment.utc(input).local().format(format);
    }

    static toDisplayDate(input: string): string {
        if (!input) return input;

        return moment.utc(input).local().format("L");
    }

    static toDisplayDateTime(input: string): string {
        if (!input) return input;

        return moment.utc(input).local().format("L LTS Z");
    }

    static toUtcDisplayDate(input: string): string {
        if (!input) return input;

        return moment.utc(input).format("L");
    }

    static toUtcDisplayDateTime(input: string): string {
        if (!input) return input;

        return moment.utc(input).format("L LTS Z");
    }

    static toISODate(input: string): string {
        if (!input) return input;

        return moment.utc(input).format("Y-MM-DD");
    }

    static toISODateTime(input: string): string {
        if (!input) return input;

        return moment(input).utc().format("Y-MM-DDTHH:mm:ss[Z]");
    }

    static toRelative(input: string): string {
        if (!input) return input;

        if (moment().diff(input, "days") > 0) {
            return RioDateHelpers.toDisplayDateTime(input);
        }

        moment.relativeTimeThreshold("s", 60);
        moment.relativeTimeThreshold("ss", 1);
        moment.relativeTimeThreshold("m", 60);
        moment.relativeTimeThreshold("h", 24);

        const relativeTime = moment.utc(input).fromNow();
        let outPutText: string;
        switch (relativeTime) {
            case "a few seconds ago":
                outPutText = "1 second ago";
                break;
            case "a minute ago":
                outPutText = "1 minute ago";
                break;
            case "an hour ago":
                outPutText = "1 hour ago";
                break;
            default:
                outPutText = relativeTime;
                break;
        }
        return outPutText;
    }

    static toUnspecifiedDateTime(input: string): string {
        if (!input) return input;

        return this.toISODateTime(input).slice(0, -1);
    }


    // --- Intl Date helpers --- //

    static parseInputDate(input?: string, utc: boolean = false): Date {
        return !!input ? new Date(input + (utc ? "Z": "")) : null;
    }

    static parseOutputDate(output?: Date, dateFormat: string = "yyyy-MM-dd'T'HH:mm:ss"): string {
        if (output === null) {
            return null;
        }

        //output = new Date(output.getTime() - (output.getTimezoneOffset() * 60 * 1000));

        return format(output, dateFormat);
    }

    static parseDisplayDate(input: string, dateFormat: string, utc: boolean = false): string {
        if (input === null) {
            return "";
        }

        return format(RioDateHelpers.parseInputDate(input, utc), dateFormat);
    }
}