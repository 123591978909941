import * as React from "react";
declare var ReactQuill: typeof import("react-quill-2");
import moment from "moment";
import { RioImageDataHelper } from "../../helpers/ImageDataHelper";

interface IHelpDialogProps {
    title: string;
}

interface IHelpDialogState {
    body: string;
    editorBody: string;
    editing: boolean;
    saving: boolean;
    currentContext: string;
    currentRevision: string;
    currentCultureId: number;
    availableContexts: string[];
    availableHistory: string[];
    availableCultures: HelpCulture[];
    canEdit: boolean;
    showTableControls: boolean;
}

interface HelpCulture {
    cultureID: string;
    name: string;
    isDefault: boolean;
    displayName: boolean;
}

export class RioHelpDialog extends React.Component<IHelpDialogProps, IHelpDialogState> {
    quill: any
    tableModule: any;

    constructor(props: IHelpDialogProps) {
        super(props);
        this.state = {
            body: "",
            editorBody: "",
            editing: false,
            saving: true,
            currentCultureId: -1,
            currentRevision: null,
            availableContexts: [],
            availableHistory: [],
            availableCultures: [],
            currentContext: props.title,
            canEdit: false,
            showTableControls: false
        };
    }

    componentDidMount() {
        this.fetchCanEdit();
        this.fetchHelpText();
        this.fetchAvailable();
        this.fetchAvailableHistory();
        this.fetchAvailableCultures();
    }

    modules = {
        toolbar: {
            container: [
                [{ "size": ["small", "large", "huge"] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ "align": "" }, { "align": "center" }, { 'align': "right" }],
                [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
                ["link", "image"],
                ["clean"],
                [{ "script": "sub" }, { "script": "super" }],
                ["table"]
            ],
            handlers: {
                image: this.imageHandler
            }
        },
        table: true
    };

    imageHandler() {

        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            this.uploadImage(file);
        };
    }

    imageDropAndPasteHandler(imageDataUrl: string, type: string, imageData: RioImageDataHelper) {

        var filename = 'image.png'
        var file = imageData.toFile(filename)
        this.uploadImage(file);
    }

    uploadImage(file: File) {
        const formData = new FormData();

        formData.append('image', file);

        // Save current cursor state
        const range = this.quill.editor.getSelection(true);

        // Insert temporary loading placeholder image
        this.quill.editor.insertEmbed(range.index, 'image', `${window.location.origin}/images/ajax-loader.gif`);

        // Move cursor to right side of image (easier to continue typing)
        this.quill.editor.setSelection(range.index + 1);


        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;
        xhr.open('POST', '/HelpTool/imageUpload/');
        let _this = this;
        xhr.onload = function () {

            _this.quill.editor.deleteText(range.index, 1);

            let resp = JSON.parse(xhr.responseText);

            // Insert uploaded image
            _this.quill.editor.insertEmbed(range.index, 'image', resp.data);

        };
        xhr.send(formData);
    }

    handleDrop(e: any) {
        e.preventDefault()
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) {
            if (document.caretRangeFromPoint) {
                const selection = document.getSelection()
                const range = document.caretRangeFromPoint(e.clientX, e.clientY)
                if (selection && range) {
                    selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset)
                }
            }
            this.readFiles(e.dataTransfer.files, (dataUrl: string, type: string) => {
                type = type || 'image/png'
                this.imageDropAndPasteHandler(dataUrl, type, new RioImageDataHelper(dataUrl, type));
            }, e)
        }
    }

	/* handle image paste event
	*/
    handlePaste(e: any) {
        if (e.clipboardData && e.clipboardData.items && e.clipboardData.items.length) {
            this.readFiles(e.clipboardData.items, (dataUrl: string, type: string) => {
                type = type || 'image/png'
                this.imageDropAndPasteHandler(dataUrl, type, new RioImageDataHelper(dataUrl, type));
            }, e)
        }
    }

    /* read the files
	*/
    readFiles(files: File[], callback: any, e: any) {
        [].forEach.call(files, (file: { type: string; getAsFile: () => any; }) => {
            var type = file.type
            if (!type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp)/i)) return
            e.preventDefault()
            const reader = new FileReader()
            reader.onload = (e) => {
                callback(e.target.result, type)
            }
            const blob = file.getAsFile ? file.getAsFile() : file
            if (blob instanceof Blob) reader.readAsDataURL(blob)
        })
    }

	/* insert into the editor
	*/
    insert(dataUrl: string, type: string) {
        const index = (this.quill.getSelection() || {}).index || this.quill.getLength()
        this.quill.insertEmbed(index, 'image', dataUrl, 'user')
    }

    formats = [
        "size",
        "bold", "italic", "underline", "strike", "blockquote", "code", "code-block",
        "align",
        "list", "bullet", "indent",
        "link", "size", "image",
        "clean", "script"
    ];

    render() {
        return (
            <div id="RioHelpDialog">
                {this.renderLibrary()}
                <div className="helpMain">
                    <div className="helpTopBar">
                        {this.renderTitle()}
                        <div className="dropdowns">
                            {this.renderHistorySection()}
                            {this.renderCulturesSection()}
                        </div>
                    </div>
                    {this.renderEditor()}
                    {this.renderActions()}
                </div>
            </div>

        );
    }

    renderTitle() {
        if (this.hasCurrentContext()) {
            return (
                <h1>{this.formatPageTitle(this.state.currentContext)}</h1>
            );
        }
    }

    renderEditor() {
        if (this.state.editing) {

            return  (
                <div>
                    {this.renderTableControls()}
  
                <ReactQuill.default
                ref={el => {
                    if (!this.quill) {
                        this.quill = el;
                        this.quill.editor.root.addEventListener('drop', this.handleDrop, false);
                        this.quill.editor.root.addEventListener('paste', this.handlePaste, false);
                        this.tableModule = this.quill.editor.getModule('table');
                        let tableButton = document.body.querySelector('.ql-table');

                        if (tableButton instanceof HTMLElement) {
                            tableButton.onclick = () => {
                                this.setState({
                                    showTableControls: true
                                });
                            };
                        }

                    }
                }}
                className="notesEditor"
                theme="snow"
                value={this.state.editorBody}
                modules={this.modules}
                formats={this.formats}
                onChange={(value: string) => this.handleEditorChanged(value)}

                    />
                    </div>);
        }
        return (
            <div className="helpArticle" dangerouslySetInnerHTML={{ __html: this.state.body }}>
            </div>
        );

    }

    renderTableControls() {
        if (this.state.showTableControls) {
            return (
                <div className="tableButtons">
                    <div className="tableButtonRow">
                        <button onClick={() => this.insertTable()}>Insert Table</button>
                        <button onClick={() => this.deleteTable()}>Delete Table</button>
                    </div>
                    <div className="tableButtonRow">
                        <button onClick={() => this.insertTableColumnLeft()}>Insert Column Left</button>
                        <button onClick={() => this.insertTableColumnRight()}>Insert Column Right</button>
                        <button onClick={() => this.deleteTableColumn()}>Delete Column</button>
                    </div>
                    <div className="tableButtonRow">
                        <button onClick={() => this.insertTableRowAbove()}>Insert Row Above</button>
                        <button onClick={() => this.insertTableRowBelow()}>Insert Row Below</button>
                        <button onClick={() => this.deleteTableRow()}>Delete Row</button>
                    </div>
                </div>
            );
        }
        return "";
    }

    renderActions() {
        if (this.state.saving) {
            return (
                <div className="helpActions">
                    <img src="/images/ajax-loader.gif"/>
                </div>
            );

        }

        if (this.state.editing) {
            return (
                <div className="helpActions">
                    <button onClick={() => this.onSaveClicked()}>Save</button>
                    <button onClick={() => this.onCancelClicked()}>Cancel</button>
                </div>

            );
        }

        if (this.state.canEdit) {

            return (
                <div className="helpActions">
                    <button onClick={() => this.onEditClicked()}>Edit</button>
                </div>
            );
        }
    }

    renderLibrary() {
        var rows = [];
        for (let i = 0; i < this.state.availableContexts.length; i++) {
            let context = this.state.availableContexts[i];
            let className = context == this.state.currentContext ? "selected" : "";
            rows.push(
                <a key={i} className={className} onClick={() => this.handleContextClicked(context)}>
                    {this.formatPageTitle(context)}
                </a>);
        }
        return (
            <div className="helpLibrary">
                <h1>Help library</h1>
                {rows}
            </div>
        );
    }

    renderHistorySection() {
        if (this.state.availableHistory && this.state.availableHistory.length && this.state.canEdit) {
            return (
                <div className="historySection">
                    <p className="historyTitle">History:</p>
                    {this.renderHistoryDropdown()}
                </div>

            );
        }
    }

    renderCulturesSection() {
        if (this.state.availableCultures && this.state.availableCultures.length) {
            return (
                <div className="culturesSection">
                    <p className="culturesTitle">Language:</p>
                    {this.renderCulturesDropdown()}
                </div>

            );
        }
    }

    renderHistoryDropdown() {
        if (this.state.availableHistory && this.state.availableHistory.length) {
            var rows = [];
            for (let i = 0; i < this.state.availableHistory.length; i++) {
                let option = this.state.availableHistory[i];
                rows.push(
                    <option value={option} key={i}>{moment(option).format("LLL")}</option>);
            }
            return (
                <select className="availableHistory" onChange={e => this.handleHistorySelectionChanged(e)}>
                    {rows}
                </select>);
        }
    }

    renderCulturesDropdown() {
        if (this.state.availableCultures && this.state.availableCultures.length) {
            var rows = [];
            for (let i = 0; i < this.state.availableCultures.length; i++) {
                let option = this.state.availableCultures[i];
                rows.push(
                    <option value={option.cultureID} key={i}>{option.displayName + ' (' + option.name + ')'}</option>);
            }
            return (
                <select className="availableCulture" onChange={e => this.handleCultureSelectionChanged(e)}>
                    {rows}
                </select>);
        }
    }

    onEditorChangedText(delta: string, old: any, source: string) {
        if (source === 'user') {
            this.setState({
                editorBody: delta
            });
        }
    }

    handleEditorChanged(value: string) {
       this.setState({
            editorBody: value
        });
    }

    handleHistorySelectionChanged(event: React.FormEvent<HTMLSelectElement>) {
        if (this.confirmSwitchEditor()) {
            this.setState({
                currentRevision: event.currentTarget.value
            }, () => {
                this.fetchHelpText();
            });
        }
    }

    handleCultureSelectionChanged(event: React.FormEvent<HTMLSelectElement>) {
        if (this.confirmSwitchEditor()) {
            this.setState({
                currentCultureId: +event.currentTarget.value
            }, () => {
                this.fetchHelpText();
                this.fetchAvailableHistory();
                this.fetchAvailable();
            });
        }
    }

    handleContextClicked(value: string) {
        if (this.confirmSwitchEditor()) {
            this.setState({
                currentContext: value,
                editing: false,
                availableHistory: []
            }, () => {
                this.quill = null;
                this.fetchHelpText();
                this.fetchAvailableHistory();
            });
        }
    }

    fetchHelpText() {
        if (!this.hasCurrentContext()) {
            this.setState({
                saving: false
            });
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        let url = '/HelpTool/entry/' + this.state.currentContext + '?cultureId=' + this.state.currentCultureId;

        if (this.state.currentRevision) {
            url += '&timestamp=' + this.state.currentRevision;
        }

        fetch(url, requestOptions)
            .then(
                response => response.json()
            )
            .then(data =>
                this.setState({
                    body: data.data.helpText,
                    editorBody: data.data.helpText,
                    saving: false
                }));
    }

    fetchAvailable() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/HelpTool/availableContexts', requestOptions)
            .then(
                response => response.json()
            )
            .then(data => {
                if (data.data.indexOf(this.state.currentContext) == -1) {
                    data.data.push(this.state.currentContext);
                }
                this.setState({
                    availableContexts: data.data,
                });
            });

    }

    fetchAvailableHistory() {
        if (this.state.canEdit) {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            };
            fetch('/HelpTool/entry/' + this.state.currentContext + '/history?cultureId=' + this.state.currentCultureId, requestOptions)
                .then(
                    response => response.json()
                )
                .then(data => {
                    this.setState({
                        availableHistory: data.data,
                    });
                });
        }
    }

    fetchAvailableCultures() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/HelpTool/availableCultures', requestOptions)
            .then(
                response => response.json()
            )
            .then(data => {
                this.setState({
                    availableCultures: data.data,
                });
            });

    }

    fetchCanEdit() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('/HelpTool/canEdit', requestOptions)
            .then(
                response => response.json()
            )
            .then(data => {
                this.setState({
                    canEdit: data.data,
                });
            });
    }

    onSaveClicked() {
        this.setState({
            editing: false,
            saving: true,
            body: this.state.editorBody
        }, () => {
            this.quill = null;
        });
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.editorBody)
        };
        fetch('/HelpTool/entry/' + this.state.currentContext + '?cultureId=' + this.state.currentCultureId, requestOptions)
            .then(data => {
                this.setState({
                    saving: false
                });
                this.fetchAvailable();
                this.fetchAvailableHistory();
            }
            );
    }

    onCancelClicked() {
        if (this.confirmSwitchEditor()) {
            this.toggleEdit();
        }
    }

    onEditClicked() {
        this.toggleEdit();
    }

    toggleEdit() {
        this.setState({
            editing: !this.state.editing
        });
    }

    hasCurrentContext() {
        return this.state.currentContext && this.state.currentContext.length;
    }

    formatPageTitle(s: string) {
        return s.replace(/([A-Z])/g, ' $1').trim()
    }

    confirmSwitchEditor(): boolean {
        return !this.state.editing || this.state.body == this.state.editorBody || confirm("You have unsaved changes. Would you like to discard them?");
    }

    deleteTable() {
        this.quill.focus();
        this.tableModule.deleteTable();
    }

    deleteTableRow() {
        this.quill.focus();
        this.tableModule.deleteRow();
    }

    insertTableRowAbove() {
        this.quill.focus();
        this.tableModule.insertRowAbove();
    }

    insertTableRowBelow() {
        this.quill.focus();
        this.tableModule.insertRowBelow();
    }

    deleteTableColumn() {
        this.quill.focus();
        this.tableModule.deleteColumn();
    }

    insertTableColumnRight() {
        this.quill.focus();
        this.tableModule.insertColumnRight();
    }

    insertTableColumnLeft() {
        this.quill.focus();
        this.tableModule.insertColumnLeft();
    }

    insertTable() {
        this.quill.focus();
         this.tableModule.insertTable(3, 3);
    }
}   