﻿import * as React from "react";
import { IRioValidationComponentProps, IRioValidatedInputProps, IRioValidatedInput, IRioValidations } from "../helpers/Validator";
import { RioForEach, RioIf, RioIfFunc } from "../helpers/logic";
import { RioUnsafe } from "./Unsafe";
import { RioNumberHelpers } from "../helpers/NumberHelpers";


interface IFinancialInputProps {
    currencySymbol?: string;
    baseCurrencySymbol?: string;
    exchangeRate?: number;
    reverse?: boolean;
    decimalPlaces?: number;
}

interface IFinancialInputState {
    blurred: boolean;
    focussed: boolean;
    displayValue: string;
}

export class RioFinancialInput extends React.Component<React.InputHTMLAttributes<HTMLInputElement> & IRioValidationComponentProps & IRioValidatedInputProps & IFinancialInputProps, IFinancialInputState> implements IRioValidatedInput { 
    protected constructor(props: Readonly<React.InputHTMLAttributes<HTMLInputElement> & IRioValidationComponentProps & IRioValidatedInputProps & IFinancialInputProps>) {
        super(props);

        this.state = {
            blurred: false,
            focussed: false,
            displayValue: props.value?.toString() ?? ""
        };
    }

    target = React.createRef<HTMLInputElement>();

    getKey(): string {
        return this.props.name;
    }

    getValue(): any {
        return this.props.value;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    handleInputChanged(e: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            blurred: false,
            displayValue: e.currentTarget.value
        });
        !!this.props.onChange && this.props.onChange(e);
    }

    handleInputBlurred(e: React.FocusEvent<HTMLInputElement>): void {
        this.setState({
            blurred: true,
            focussed: false
        });
        if (this.props.disableCheckOnBlur) return;
        !!this.props.validator && this.props.validator.validate(this);
        !!this.props.onBlur && this.props.onBlur(e);
    }

    handleInputFocussed(e: React.FocusEvent<HTMLInputElement>): void {
        this.setState({ focussed: true });
        !!this.props.onFocus && this.props.onFocus(e);
    }

    handleInputLoaded(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    handleInputUnloaded(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    componentDidMount(): void {
        this.handleInputLoaded();
    }

    componentWillUnmount(): void {
        this.handleInputUnloaded();
    }

    private decimalPlaces = this.props.decimalPlaces ?? 2;

    render() {
        const hasValidationErrors = !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred) && this.props.validator.getErrors(this).length > 0;
        const baseCurrency = this.props.baseCurrencySymbol ?? "&pound;";

        const p = { ...this.props };
        //remove all non standard input props to prevent console errors on the input
        delete p['exchangeRate'];
        delete p['baseCurrencySymbol'];
        delete p['currencySymbol'];
        delete p['reverse'];
        delete p['decimalPlaces'];
        delete p['value']; //remove value as it will be calculated as a display value
        delete p['className']; //remove className from the core input, the input can still be referred to as the class is on the parent
        delete p['type']; //remove type as it is manually set
        delete p['onChange']; //remove onChange as it is manually set
        delete p['onBlur']; //remove onBlur as it is manually set

        return (
            <React.Fragment>
                <div className={`rioFinancialInputWrapper ${!!this.props.className ? this.props.className : ""} ${hasValidationErrors ? "fieldValidationFailed" : ""}`}>
                    <RioIf condition={!!this.props.currencySymbol} then={
                        <div className="rioFinancialInputCurrencySymbol">
                            <RioUnsafe html={this.props.currencySymbol} />
                        </div>
                    } />
                    <div className="rioFinancialInput">
                        <input {...p}
                            value={!!this.state.focussed && !this.props.disabled && !this.props.readOnly ? this.state.displayValue : this.getFormattedValue(this.props.value)}
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleInputChanged(e)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement>) => this.handleInputBlurred(e)}
                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => this.handleInputFocussed(e)}
                            ref={this.target} />
                    </div>
                    <RioIf condition={!!this.props.currencySymbol && !!this.props.exchangeRate && this.props.currencySymbol != baseCurrency} then={
                        <div className="rioFinancialInputBaseCurrencyValue">
                            <span>(<RioUnsafe html={baseCurrency} /> {this.applyExchangeRate(this.getFormattedValue(this.target?.current?.value), this.props.reverse)})</span>
                        </div>
                    } />
                </div>
                <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                    return <RioForEach items={this.props.validator.getErrors(this)} perform={error => {
                        return <div key={error} className="fieldValidationError">{error}</div>;
                    }} />;
                }} />
            </React.Fragment>
        );
    }

    applyExchangeRate(textInput: string, reverse: boolean = false): string {
        let input = parseFloat(textInput);

        if (!this.props.exchangeRate) return ""
        if (!input) return RioNumberHelpers.numberWithCommas(0, this.decimalPlaces);

        let convertedInput = this.props.exchangeRate ? (!!reverse ? (input / this.props.exchangeRate) : (input * this.props.exchangeRate)) : input;
        if (isNaN(convertedInput)) {
            convertedInput = 0;
        }
        return RioNumberHelpers.numberWithCommas(convertedInput, this.decimalPlaces);
    }

    getFormattedValue(value: string | number | readonly string[]): string {
        let valueAsNumber: number;
        valueAsNumber = parseFloat(value?.toString());

        if (isNaN(valueAsNumber)) {
            return "";
        }

        return RioNumberHelpers.roundToDecimalPlaces(valueAsNumber, this.decimalPlaces).toFixed(this.decimalPlaces);
    }
}