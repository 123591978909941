﻿import * as React from "react";
import { RioValidator, IRioValidatedInputProps, IRioValidatedInputState, IRioValidatedInput, IRioValidations } from "../helpers/Validator";
import { RioIfFunc, RioForEach } from "../helpers/logic";

interface IRioValidationSummaryProps {
    validator: RioValidator;
    name?: string;
    className?: string;
}

interface IRioValidationSummaryState {

}

export class RioValidationSummary extends React.Component<IRioValidationSummaryProps & IRioValidatedInputProps, IRioValidationSummaryState & IRioValidatedInputState> implements IRioValidatedInput {
    getKey(): string {
        return !!this.props.name ? this.props.name : "ValidationSummary";
    }

    getValue(): any {
        return null;
    }

    getValidations(): IRioValidations {
        return this.props.validations;
    }

    constructor(props: Readonly<IRioValidationSummaryProps & IRioValidatedInputProps>) {
        super(props);

        this.state = {
            blurred: false
        };
    }

    componentDidMount(): void {
        !!this.props.validator && this.props.validator.register(this);
    }

    componentWillUnmount(): void {
        !!this.props.validator && this.props.validator.unregister(this);
    }

    render() {
        const hasValidationErrors = !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred) && this.props.validator.getErrors(this).length > 0;
        var className = "validationSummary" + (!!this.props.className ? " " + this.props.className : "") + (this.props.validator.getErrors(this).length == 0 ? " hide" : "");
        return (
            <React.Fragment>
                <div className={className}>
                    <RioIfFunc condition={!this.props.hideValidationText && !!this.props.validator && (this.props.validator.submitAttempted || this.state.blurred)} then={() => {
                        return <RioForEach items={this.props.validator.getErrors(this)} perform={(error, index) => {
                            return <div key={`${index}-${error}`} className="fieldValidationError">{error}</div>;
                        }} />;
                    }} />
                </div>
                
            </React.Fragment>
        );
    }
}