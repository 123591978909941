import { RioPermissionFlags } from "../enums/PermissionFlags";
import { RioArrayHelpers } from "./ArrayHelpers";
import { IRioPermission } from "../apiModels/IRioPermission"

export class RioPermissions {
    static permissions: Array<IRioPermission>;

    static checkPermissions(controllerName: string, requiredPermissions: RioPermissionFlags): boolean {
        return requiredPermissions == RioPermissionFlags.None ||
            !controllerName ||
            RioArrayHelpers.any(RioPermissions.permissions, (p: IRioPermission) => p.controller === controllerName && (requiredPermissions === (p.permissions & requiredPermissions)));
    }
}

export function setRioPermissions(newPermissions: Array<IRioPermission>) {
    RioPermissions.permissions = newPermissions;
}