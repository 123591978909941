﻿import { RioArrayHelpers } from "./ArrayHelpers";

export class RioDelimiterHelper {
    static stringToArray(input: string, delimiter: string = ","): Array<string> {
        const valueArray = input.split(delimiter);
        for (let i = 0; i < valueArray.length; i++) {
            valueArray[i] = valueArray[i].trim();
        }
        return RioArrayHelpers.exceptAny(valueArray, value => value === "");
    }
}