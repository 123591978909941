﻿import * as React from "react";
import { IRioResource } from "../../helpers/Resources";
import { RioPageHeading } from "./PageHeading";


interface IRioPageProps {
    heading: IRioResource;
    className?: string;
    hideImage?: boolean;
    hideText?: boolean;
}

export class RioPage extends React.Component<IRioPageProps> {
    render(): Object | string | number | {} | Object | Object | boolean {
        return (
            <React.Fragment>
                <RioPageHeading className={this.props.className} resource={this.props.heading} hideImage={this.props.hideImage} hideText={this.props.hideText} />
                <section className="content">
                    {this.props.children}
                </section>
            </React.Fragment>
        );
    }
}