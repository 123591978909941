﻿import * as React from "react";
import { TextFilterOption } from "../../enums/TextFilterOption";
import { RioIconButton } from "../../components/IconButton";
import { RioResources } from "../../helpers/Resources";
import { RioIcon } from "../../components/Icon";


interface ITextFilterComponentProps {
    onChange: (inputValue: string, dropdownValue: TextFilterOption) => void;
    closeFilter: () => void;
}

interface ITextFilterComponentState {
    inputValue: string;
    dropdownValue: TextFilterOption;
}

export class TextFilterComponent extends React.Component<ITextFilterComponentProps, ITextFilterComponentState> {
    constructor(props: Readonly<ITextFilterComponentProps>) {
        super(props);

        this.state = {
            inputValue: "",
            dropdownValue: 0
        };
    }

    private filterWrapperElement = React.createRef<HTMLTableElement>();

    render() {
        return (
            <table ref={this.filterWrapperElement} className="filterWrapper">
                <tbody>
                    <tr className="closeButtonRow">
                        <td></td>
                        <td></td>
                        <td>
                            <RioIconButton resource={RioResources.find("FilterClose")} hideText={true} onClick={() => this.props.closeFilter()} hasPermission={true} />
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("FilterType").text}:</span>
                        </td>
                        <td>
                            <select onChange={(e) => this.handleDropDownChanged(parseInt(e.currentTarget.value))}>
                                <option value={0}>{RioResources.find("StartsWith").text}</option>
                                <option value={1}>{RioResources.find("EndsWith").text}</option>
                                <option value={2}>{RioResources.find("Contains").text}</option>
                            </select>
                        </td>
                        <td>
                            { /* empty to make sure filter button lines up */}
                        </td>
                    </tr>
                    <tr className="filterInput">
                        <td>
                            <span>{RioResources.find("Text").text}:</span>
                        </td>
                        <td>
                            <input type="text" value={!!this.state.inputValue ? this.state.inputValue : ""} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ inputValue: e.target.value }); this.props.onChange(e.target.value, this.state.dropdownValue); }}></input>
                        </td>
                        <td>
                            <button className="clearFilterButton" onClick={() => { this.setState({ inputValue: "" }); this.props.onChange("", this.state.dropdownValue); }}>
                                <RioIcon resource={RioResources.find("ClearFilterIcon")} />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    componentDidUpdate() {
        this.checkIfOffScreen();
    }

    checkIfOffScreen() {
        if (!!this.filterWrapperElement.current) {
            const filterWindowElement = this.filterWrapperElement.current.parentElement;
            const filterWindowElementPosition = filterWindowElement.getBoundingClientRect();

            const tableRowElement = filterWindowElement.parentElement.parentElement;
            const tableRowElementPosition = tableRowElement.getBoundingClientRect();

            if (filterWindowElementPosition.right > tableRowElementPosition.right) {
                filterWindowElement.classList.add("right");
            }
        }
    }

    handleDropDownChanged(value: number) {
        let result = isNaN(value) ? TextFilterOption.StartsWith : value
        this.setState({
            dropdownValue: isNaN(value) ? TextFilterOption.StartsWith : value
        });

        this.props.onChange(this.state.inputValue, result)
    }

    clearFilter() {
        this.setState({
            inputValue: ""
        });
        this.props.onChange("", this.state.dropdownValue);
    }
}