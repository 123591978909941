import { ApiResponse } from "./ApiResponse";

export class ApiErrorHandler{

    /**
     * Run if any api request in the application returns a 401, 
     * typically so that the application can redirect to the login page
     */
    static onUnauthorised: (response: ApiResponse<any>) => any;

    /**
     * Run if any api request in the application returns a 503, 
     * typically so that the application can redirect to an app maintenance page
     */
    static onServiceUnavailable: (response: ApiResponse<any>) => any;
}