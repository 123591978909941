﻿import * as React from "react";
import { RioIfFunc, RioIf } from "../helpers/logic";
import { IRioResource } from "../helpers/Resources";
import { RioUnsafe } from "../components/Unsafe";

interface IIconButtonProps {
    resource: IRioResource;

    busyResource?: IRioResource;
    busy?: boolean;

    hideText?: boolean;
    hideImage?: boolean;
    className?: string;
    disabled?: boolean;

    hasPermission: boolean;

    onClick: () => void;
    onMiddleClick?: () => void;
}

export class RioIconButton extends React.Component<IIconButtonProps> {
    constructor(props: IIconButtonProps) {
        super(props);

        this.handleMouseUp = this.handleMouseUp.bind(this);
    }

    render() {
        let title = this.props.resource.text;
        if (!this.props.hideText) {
            title = "";
        }
        if (this.props.hasPermission == false) {
            return null;
        }
        return (
            <button
                className={!!this.props.className ? this.props.className : undefined}
                title={title}
                disabled={this.props.disabled || this.props.busy}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleClick(e)}
                onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => e.button === 1 && e.preventDefault()}
                onMouseUp={(e: React.MouseEvent<HTMLButtonElement>) => this.handleMouseUp(e)}
            >
                <RioIfFunc condition={!this.props.busy || !this.props.busyResource} then={() =>
                    <React.Fragment>
                        <RioIf condition={!!this.props.resource.image && !this.props.hideImage} then={
                            <RioUnsafe html={this.props.resource.image} />
                        } />
                        <RioIf condition={!this.props.hideText} then={
                            <span>{this.props.resource.text}</span>
                        } />
                    </React.Fragment>
                } otherwise={() =>
                    <React.Fragment>
                        <RioIf condition={!!this.props.resource.image && !this.props.hideImage} then={
                            <RioUnsafe html={this.props.resource.image} />
                        } />
                        <RioIf condition={!this.props.hideText} then={
                            <span>{this.props.busyResource.text}</span>
                        } />
                    </React.Fragment>
                } />
            </button>
        );
    }

    handleClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();

        this.props.onClick();
    }

    handleMouseUp(e: React.MouseEvent<HTMLButtonElement>) {
        if (e.button === 1 && !!this.props.onMiddleClick) {
            e.stopPropagation();
            this.props.onMiddleClick();
        }
    }
}