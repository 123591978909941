﻿import * as React from "react";
import { Filter } from "../../enums/Filter";
import { RioPager } from "./Pager";
import { DateFilter } from "../../filters/date/DateFilter";
import { BoolFilter } from "../../filters/bool/BoolFilter";
import { NumberFilter } from "../../filters/number/NumberFilter";
import { TextFilter } from "../../filters/text/TextFilter";
import { IGridViewFilter } from "../../filters/IGridViewFilter";
import { IGridViewAction } from "./IGridViewAction";
import { IGridViewColumn } from "./IGridViewColumn";
import { RioResources, IRioResource } from "../../helpers/Resources";
import { RioArrayHelpers } from "../../helpers/ArrayHelpers";
import { RioIf, RioForEach, RioIfFunc } from "../../helpers/logic";
import { RioStringHelpers } from "../../helpers/StringHelpers";
import { RioCheckbox } from "../Checkbox";
import { RioIcon } from "../Icon";
import { RioIconButton } from "../IconButton";
import { RioInput } from "../Input";
import { RioUnsafe } from "../Unsafe";

interface IGridViewProps<T> {
    noResults: IRioResource;
    items: Array<T>;
    columns: Array<IGridViewColumn<T>>;
    actions?: Array<IGridViewAction<T>>;
    rowKey: (item: T, index: number) => number | string;
    rowClassName?: (item: T) => string;
    rowIsSelectable?: (item: T) => boolean;
    selectableRowTitle?: string;
    filterDateFormat?: string;
    filterDateTimeFormat?: string;
    onSelectedChanged?: (selected: Array<T>) => void;
}

interface IGridViewState<T> {
    filteredItems: Array<T>;
    selectedItems: Array<T>;
    settledColumns: Array<IGridViewColumn<T>>;
    page: number;
    pageSize: number;
    sortColumn: number; /** 1-based index - value of 0 is no sort column, negative value is a reverse (descending) sort */
    displayFilterColumn: number;
    searchableInputText: string;
}

export class RioGridView<T> extends React.Component<IGridViewProps<T>, IGridViewState<T>> {
    constructor(props: Readonly<IGridViewProps<T>>) {
        super(props);

        this.state = {
            filteredItems: [],
            selectedItems: [],
            settledColumns: [],
            page: 1,
            pageSize: 10,
            sortColumn: 0,
            displayFilterColumn: null,
            searchableInputText: ""
        };
    }

    private activeFilterDiv = React.createRef<HTMLDivElement>();


    render() {
        const hasActions = !!this.props.actions && this.props.actions.length > 0;
        const hasFilters = RioArrayHelpers.any(this.props.columns, x => x.renderedFilter != null);
        const sortedArray = this.sortItems();
        const pageItems = !sortedArray ? null : RioArrayHelpers.page(sortedArray, this.state.page, this.state.pageSize);

        return (
            <React.Fragment>
                { /* GridViewStart */}
                <div className="rioGridView" onKeyDown={(e: React.KeyboardEvent) => this.handleKeyDown(e)}>
                    { /* searchable combobox */}
                    <RioIf condition={RioArrayHelpers.any(this.props.columns, x => x.searchable)} then={
                        <div className="searchableInputTextWrapper">
                            <span className="searchableInputLabel">{RioResources.find("QuickSearch").text}:</span>
                            <RioInput name="searchableInputText" value={this.state.searchableInputText} 
                                onChange={(e: { currentTarget: { value: string; }; }) => this.handleSearchableInputTextChanged(e.currentTarget.value)} />
                            <RioIcon resource={RioResources.find("Search")} />
                        </div>
                    } />
                    <div className="autoscrollHorizontal">
                        <table className="full-width">
                            <thead>
                                <tr>
                                    { /* We add an extra column for checkboxes if a function to determine the row is selectable is provided */}
                                    <RioIf condition={!!this.props.rowIsSelectable} then={
                                        <th>
                                            <span className="gridViewColumnTitle">{this.props.selectableRowTitle ?? ""}</span>
                                        </th>
                                    } />
                                    <RioForEach items={this.state.settledColumns} perform={(column: IGridViewColumn<T>, index: number) => {
                                        if (column.hidden) return null;
                                        if (!!column.renderedFilter) {
                                            return (
                                                <th key={column.title} className={`${!column.disableSorting ? "sortable" : ""} ${!!column.titleClassName ? column.titleClassName : ""}`} onClick={() => this.handleColumnClicked(index)}>
                                                    <span className="gridViewColumnTitle">{column.title}</span>
                                                    <div className="headerIconWrapper">
                                                        <RioIconButton className={column.renderedFilter.filterActive() ? "FilterButton active" : "FilterButton"} resource={RioResources.find("Filter")} hideText={true} onClick={() => this.handleFilterClicked(index)} hasPermission={true} />
                                                        <div className="sortIcon">
                                                            {this.renderSortState(index)}
                                                        </div>
                                                    </div>
                                                    <div ref={index === this.state.displayFilterColumn ? this.activeFilterDiv : null} className={index === this.state.displayFilterColumn ? "gridViewFilters active" : "gridViewFilters"} tabIndex={0} onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}>
                                                        {column.renderedFilter.render()}
                                                    </div>
                                                    <RioIf condition={!!column.customHeadingContent} then={
                                                        <div>{column.customHeadingContent && column.customHeadingContent()}</div>
                                                    } />
                                                </th>
                                            );
                                        } else {
                                            return (
                                                <th key={column.title} className={`${!column.disableSorting ? "sortable" : ""} ${!!column.titleClassName ? column.titleClassName : ""}`} onClick={() => this.handleColumnClicked(index)}>
                                                    <RioIf condition={!column.blankHeader} then={
                                                        <React.Fragment>
                                                            <span className="gridViewColumnTitle">{column.title}</span>

                                                            <div className="sortIcon">
                                                                {this.renderSortState(index)}
                                                            </div>

                                                            <RioIf condition={!!column.customHeadingContent} then={
                                                                <div>{column.customHeadingContent && column.customHeadingContent()}</div>
                                                            } />
                                                        </React.Fragment>
                                                    } />
                                                </th>
                                            );
                                        }

                                    }} />
                                    { /* If an action or filter is provided we create another column for the actions or the clearfilters button */}
                                    <RioIf condition={hasActions || hasFilters} then={
                                        <th className="filterActionColumn">
                                            <RioIf condition={hasFilters} then={
                                                <RioIconButton resource={RioResources.find("ClearFilterIcon")} hideText={true} className="gridViewClearAllFilterButton" onClick={() => this.clearAllFilters()} hasPermission={true} />
                                            } />
                                        </th>
                                    } />
                                </tr>
                            </thead>
                            <tbody>
                                <RioIf condition={!!this.state.filteredItems && this.state.filteredItems.length > 0} then={
                                    <React.Fragment>
                                        { /* We only want to show the paged items */}
                                        <RioForEach items={pageItems} perform={(item: T, index: number) => {
                                            return (
                                                <tr key={this.props.rowKey(item, index)} className={this.determineRowClassName(item)}>
                                                    <RioIf condition={!!this.props.rowIsSelectable} then={
                                                        <td>
                                                            <RioIf condition={!!this.props.rowIsSelectable && this.props.rowIsSelectable(item)} then={
                                                                <RioCheckbox checked={!!this.props.rowIsSelectable && this.state.selectedItems.includes(item)} onChange={() => this.handleSelectedCheckClicked(item)} />
                                                            } />
                                                        </td>
                                                    } />
                                                    <RioForEach items={this.state.settledColumns} perform={(column: IGridViewColumn<T>) => {
                                                        if (column.hidden) return null;
                                                        return (
                                                            <td key={column.title} className={column.className ? column.className(item) : undefined} onClick={() => column.onItemClick ? column.onItemClick(item) : this.handleRowClicked(item)}>
                                                                {!!column.displayValue ? column.displayValue(item, index) : column.value(item)}
                                                            </td>
                                                        );
                                                    }} />
                                                    { /* Display actions */}
                                                    <RioIf condition={hasActions} then={
                                                        <td className="actions">
                                                            <RioForEach items={this.props.actions} perform={(action: IGridViewAction<T>) => {
                                                                if (!!action.visible && !action.visible(item)) return null;
                                                                if (!!action.customDisplay) {
                                                                    return (action.customDisplay(item));
                                                                } else {
                                                                    return (<RioIconButton className={action.className} resource={action.resource} hideText={true} onClick={() => action.onClick(item)} onMiddleClick={() => !!action.onMiddleClick && action.onMiddleClick(item)} key={action.resource.text} hasPermission={action.hasPermission} disabled={!!action.disabled && !!action.disabled(item)} />);
                                                                }
                                                            }} />
                                                        </td>
                                                    } />
                                                </tr>
                                            );
                                        }} />
                                        <RioIf condition={RioArrayHelpers.any(this.state.settledColumns, c => !!c.displayTotal)} then={
                                            <tr className="gridview-totals">
                                                <RioForEach items={this.state.settledColumns} perform={(column: IGridViewColumn<T>, index: number) => {
                                                    if (column.hidden) return null;
                                                    return (
                                                        <td key={column.title}>
                                                            <RioIfFunc condition={!!column.displayTotal} then={() => {
                                                                return (
                                                                    <strong>

                                                                    </strong>
                                                                );
                                                            }} otherwise={() => {
                                                                return (
                                                                    <RioIf condition={!column.displayTotal && index < 1} then={
                                                                        <strong>Total:</strong>
                                                                    } />
                                                                );
                                                            }} />
                                                        </td>
                                                    );
                                                }} />
                                                <RioIf condition={hasActions} then={
                                                    <td className="actions"></td>
                                                } />
                                            </tr>
                                        } />
                                    </React.Fragment>
                                } otherwise={
                                    <tr>
                                        <td colSpan={this.state.settledColumns.length + (hasActions || hasFilters ? 1 : 0) + (!!this.props.rowIsSelectable ? 1 : 0)}> { /* rowIsSelectable is a function, this is a check if the function is provided*/}
                                            {this.props.noResults.text}
                                        </td>
                                    </tr>
                                } />
                            </tbody>
                        </table>
                    </div>
                    <RioIf condition={!!this.state.filteredItems} then={
                        <div className="pages">
                            <RioPager currentPage={this.state.page} pageSize={this.state.pageSize} itemCount={this.state.filteredItems.length} onPageChanged={(newPage: number) => this.handlePageChanged(newPage)} />
                        </div>
                    } />
                </div>
            </React.Fragment>
        );
    }

    componentDidMount() {
        this.settleColumns();
        this.filterItems(null, null, true);
    }

    componentWillReceiveProps(nextProps: IGridViewProps<T>) {
        if (nextProps.items !== this.props.items) {
            this.filterItems(nextProps.columns, nextProps.items);
        }
    }

    //we need to build the filters into the columns based on the filter type
    settleColumns() {
        var settledColumns: Array<IGridViewColumn<T>> = [];

        this.props.columns.forEach((column, index) => {
            let filter: IGridViewFilter;
            switch (column.filter) {
                case Filter.TextFilter:
                    filter = new TextFilter(() => this.filterItems(), () => this.filterClose());
                    break;
                case Filter.NumberFilter:
                    filter = new NumberFilter(() => this.filterItems(), () => this.filterClose());
                    break;
                case Filter.DateFilter:
                    filter = new DateFilter(() => this.filterItems(), () => this.filterClose(), this.props.filterDateFormat, false);
                    break;
                case Filter.DateTimeFilter:
                    filter = new DateFilter(() => this.filterItems(), () => this.filterClose(), this.props.filterDateTimeFormat, true);
                    break;
                case Filter.BoolFilter:
                    filter = new BoolFilter(() => this.filterItems(), () => this.filterClose());
                    break;
                case Filter.None:
                    filter = null;
                    break;
                default:
                    filter = null;
                    break;
            }

            var settledColumn: IGridViewColumn<T> = {
                title: column.title, displayValue: column.displayValue, value: column.value, className: column.className,
                titleClassName: column.titleClassName, disableSorting: column.disableSorting, hidden: column.hidden, blankHeader: column.blankHeader,
                customHeadingContent: column.customHeadingContent, displayTotal: column.displayTotal, onItemClick: column.onItemClick, totalReducer: column.totalReducer,
                filter: column.filter, renderedFilter: filter, searchable: column.searchable
            }

            settledColumns.push(settledColumn);
        });

        this.setState({
            settledColumns: settledColumns
        });
    }

    filterItems(nextPropColumns: Array<IGridViewColumn<T>> = null, nextPropItems: Array<T> = null, isMounting = false) {
        let filteredArray: Array<T>;
        let columns = nextPropColumns ?? this.state.settledColumns;
        let items = nextPropItems ?? this.props.items;
        let searchTerms = RioStringHelpers.removeDiacritics(this.state.searchableInputText).trim().toLowerCase().split(" ");

        //filter by search input (only if there is at least 1 searchable column and search term)
        if (RioArrayHelpers.any(searchTerms, x => !!x.trim()) && RioArrayHelpers.any(columns, x => x.searchable)) {
            filteredArray = items.filter(item => {
                var includeItem = false;
                for (let i = 0; i < columns.length; i++) {
                    if (!columns[i].searchable) {
                        continue;
                    }
                    for (let j = 0; j < searchTerms.length; j++) {
                        let valueToSearch = !!columns[i].displayValue ? RioStringHelpers.removeDiacritics(columns[i].displayValue(item)?.toString().toLowerCase() ?? "") : RioStringHelpers.removeDiacritics(columns[i].value(item)?.toString().toLowerCase() ?? "")
                        if (!!searchTerms[j].trim() && valueToSearch.includes(searchTerms[j])) {
                            includeItem = true;
                            break;
                        }
                    }
                }
                return includeItem;
            });
        } else {
            filteredArray = items
        }

        //filter by filter control
        filteredArray = filteredArray.filter(item => {
            var includeItem = true;
            for (let i = 0; i < columns.length; i++) {
                if (!!columns[i].renderedFilter && !!columns[i].value && !columns[i].renderedFilter.validate(columns[i].value(item))) {
                    includeItem = false;
                    break;
                }
            }
            return includeItem;
        });

        let totalPages = Math.max(Math.ceil(filteredArray.length / this.state.pageSize), 1); //always at least 1 page

        this.setState({
            filteredItems: filteredArray,
            page: Math.min(this.state.page, totalPages, 1) //if we were on a page that no longer exists make sure we go to the latest.
        });

        if (!isMounting) {
            this.handleSelectedChanged([])
        }
    }

    clearAllFilters() {
        for (let i = 0; i < this.state.settledColumns.length; i++) {
            if (!!this.state.settledColumns[i].renderedFilter) {
                this.state.settledColumns[i].renderedFilter.clearFilter();
            }
        }
    }

    handlePageChanged(newPage: number): void {
        this.setState({
            page: newPage
        });
    }

    handleRowClicked(item: T): void {
        const defaultAction = this.getDefaultAction();

        if (defaultAction != undefined && (defaultAction.visible == undefined || defaultAction.visible(item))) {
            defaultAction.onClick(item);
        } else if (!!this.props.rowIsSelectable && this.props.rowIsSelectable(item)) {
            this.handleSelectedCheckClicked(item);
        }
    }

    handleColumnClicked(index: number): void {
        if (this.props.columns[index].disableSorting) return;
        if (Math.abs(this.state.sortColumn) !== (index + 1)) {
            this.setState({
                sortColumn: index + 1
            });
        } else {
            this.setState({
                sortColumn: -this.state.sortColumn
            });
        }
    }

    handleFilterClicked(index: number) {
        this.setState({
            displayFilterColumn: index
        });

        if (!!this.activeFilterDiv?.current) {
            this.activeFilterDiv.current.focus();
        }
    }

    handleKeyDown(e: React.KeyboardEvent) {
        if (e.keyCode === 27) { //escape
            this.filterClose();
        }
    }

    filterClose() {
        if (this.state.displayFilterColumn !== null) {
            this.setState({
                displayFilterColumn: null
            });
        }
    }

    sortItems(): Array<T> {
        if (this.state.sortColumn === 0) return this.state.filteredItems;

        const sortColumn = this.props.columns[Math.abs(this.state.sortColumn) - 1];

        let sortedArray: Array<T>;

        sortedArray = this.state.filteredItems.sort((a, b) => {
            if (typeof sortColumn.value(a) === "number" && typeof sortColumn.value(b) === "number") {
                return sortColumn.value(a) > sortColumn.value(b) ? 1 : -1;
            } else {
                var sortedA = sortColumn.value(a) == null ? "" : sortColumn.value(a);
                var sortedB = sortColumn.value(b) == null ? "" : sortColumn.value(b);;
                return sortedA.toString().localeCompare(sortedB.toString());
            }

        });

        if (this.state.sortColumn < 0) {
            sortedArray = sortedArray.reverse();
        }

        return sortedArray;
    }

    renderSortState(columnIndex: number): string | JSX.Element {
        // Sorting disabled
        if (this.props.columns[columnIndex].disableSorting) return "";

        const sortIndex: number = columnIndex + 1;

        if (Math.abs(this.state.sortColumn) === sortIndex) {
            // Sorting on this column
            if (this.state.sortColumn > 0) {
                return <RioUnsafe html={RioResources.find("SortUpArrow").image} />;
            } else {
                return <RioUnsafe html={RioResources.find("SortDownArrow").image} />;
            }
        } else {
            // Sortable but not sorted
            return <RioUnsafe html={RioResources.find("SortUpDownArrows").image} />;
        }
    }

    determineRowClassName(item: T): string {
        let className = "";
        const defaultAction = this.getDefaultAction();
        if (defaultAction != undefined && (defaultAction.visible == undefined || defaultAction.visible(item))) {
            className = className + " clickable";
        }

        if (!!this.props.rowClassName) {
            className = className + " " + this.props.rowClassName(item);
        }

        return className;
    }

    getDefaultAction(): IGridViewAction<T> {
        if (this.props.actions == undefined) return null;
        return this.props.actions.find(action => action.default);
    }

    handleSelectedCheckClicked(item: T) {
        let index = this.state.selectedItems.findIndex(x => x == item);
        let items = this.state.selectedItems;
        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(item);
        }

        this.handleSelectedChanged(items);
    }

    getSelectedItems(): Array<T> {
        return this.state.selectedItems;
    }

    deselectAll() {
        this.handleSelectedChanged([]);
    }

    selectAll() {
        let items = [];
        for (let i = 0; i < this.state.filteredItems.length; i++) {
            items.push(this.state.filteredItems[i]);
        }
        this.handleSelectedChanged(items);
    }

    handleSearchableInputTextChanged(value: string) {
        this.setState({
            searchableInputText: value
        }, () => this.filterItems());
    }

    handleSelectedChanged(items: Array<T>) {
        this.setState({
            selectedItems: items
        }, () => {
            if (this.props.onSelectedChanged) {
                this.props.onSelectedChanged(items);
            }
        });
    }
}


